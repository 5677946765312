const prependUrl = "/experiences/";
export const ApiUrls = {
    experience: {
        getPostByCategory: (catId) => { return '/api/experience/category/' + catId},
        addExperience: prependUrl + 'save-experience/',
        deleteExperience: prependUrl + 'delete-post/',
        getReply: prependUrl + 'reply/',
        addReply: prependUrl + 'reply-post/',
        editReply: prependUrl + 'edit-reply/',
        deleteReply: prependUrl + 'delete-reply/',
        subscribe: '/api/experience/subscribe/',
        getSubscribitions: prependUrl + 'get-subscriptions/',
        deleteSubscribtions: prependUrl + 'delete-subscription/',
        favorite: prependUrl + 'add-favorites/',
        getFavorite: prependUrl + 'get-favorites/',
        deleteFavorite: prependUrl + 'delete-favorites/',
        rateLike: '/api/experience/like/',
        rateDisLike: '/api/experience/dislike/',
        getCount: '/api/experiences/all-count/'
    },
    login: {
        fb: "https://www.facebook.com/login.php?skip_api_login=1&api_key=420354303420971&kid_directed_site=0&app_id=420354303420971&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fv3.3%2Fdialog%2Foauth%3Fapp_id%3D420354303420971%26cbt%3D1663693577032%26channel_url%3Dhttps%253A%252F%252Fstaticxx.facebook.com%252Fx%252Fconnect%252Fxd_arbiter%252F%253Fversion%253D46%2523cb%253Dfc60ba4d4eab64%2526domain%253Dwww.immihelp.com%2526is_canvas%253Dfalse%2526origin%253Dhttps%25253A%25252F%25252Fwww.immihelp.com%25252Ff6a1739135a818%2526relation%253Dopener%26client_id%3D420354303420971%26display%3Dpopup%26domain%3Dwww.immihelp.com%26e2e%3D%257B%257D%26fallback_redirect_uri%3Dhttps%253A%252F%252Fwww.immihelp.com%252Fforum%252F%26locale%3Den_US%26logger_id%3Dfe0e610feb7678%26origin%3D1%26redirect_uri%3Dhttps%253A%252F%252Fstaticxx.facebook.com%252Fx%252Fconnect%252Fxd_arbiter%252F%253Fversion%253D46%2523cb%253Df25dee856cfac8%2526domain%253Dwww.immihelp.com%2526is_canvas%253Dfalse%2526origin%253Dhttps%25253A%25252F%25252Fwww.immihelp.com%25252Ff6a1739135a818%2526relation%253Dopener%2526frame%253Df94c86880b104c%26response_type%3Dtoken%252Csigned_request%252Cgraph_domain%26scope%3Dpublic_profile%252Cemail%26sdk%3Djoey%26version%3Dv3.3%26ret%3Dlogin%26fbapp_pres%3D0%26tp%3Dunspecified&cancel_url=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df25dee856cfac8%26domain%3Dwww.immihelp.com%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fwww.immihelp.com%252Ff6a1739135a818%26relation%3Dopener%26frame%3Df94c86880b104c%26error%3Daccess_denied%26error_code%3D200%26error_description%3DPermissions%2Berror%26error_reason%3Duser_denied&display=popup&locale=en_US&pl_dbl=0",
        forum: "/forum/auth/ajax-login",
        logout: window.immiLang.logout,
        isUserLoggedIn: '/api/user-authentication/'
    },
    travelCompanion: {
        replyToPost: '/api/travel-companion/save-reply/',
        addPost: '/api/travel-companion/add/',
        searchPost: '/api/travel-companion/search/',
        deleteAd: '/api/travel-companion/delete-ad/'
    },
    cspa: {
        calculate: '/api/cspa-calculator/check-eligibility/'
    },
    babyNames: {
        addFavorites: '/api/baby-names/add-favorite/',
        removeFavorites: '/api/baby-names/delete-favorite/'
    },
    vb: {
        deleteRow: '/api/delete-row/',
        deleteColumn: '/api/delete-column/',
        masterRows: '/api/visa-bulletin/get-master-rows/',
        masterColumn: '/api/visa-bulletin/get-master-columns/',
        latestVb: '/api/vb-get-latest-record/',
        addVBMaster: '/api/vb-add-new-record/',
        getTracker: '/api/visa-bulletin/get-tracker/',
        latestMovement: '/api/visa-bulletin/get-latest-movement/'
    },
    blog: {
        addBlogComment: '/api/save-blog-comment/',
        rateLike: '/api/blog-comment/like/',
        rateDisLike: '/api/blog-comment/dislike/'
    },
    citizenshipCalc: {
        save: '/api/us-citizenship-calculator/save-progress/',
        retrieve: '/api/us-citizenship-calculator/retrieve-progress/'
    },
    sponsors: {
        upload: '/api/sponsors/upload/',
        status: '/api/sponsors/task-status/',
        triggerAddrValid: '/api/sponsors/address-validation/',
        getReplies: '/api/sponsors/get-employer-replies/',
        postLike: '/api/sponsors/rate-like/',
        postDisLike: '/api/sponsors/rate-dislike/',
        subscribe: '/api/sponsors/subscribe/',
        favorite: '/api/sponsors/favorite/',
        addReview: '/api/sponsors/add-review/',
        addReply: '/api/sponsors/add-reply/',
        unsubscribe: '/api/sponsors/un-subscribe/',
        deleteFavorite: '/api/sponsors/delete-favorite/',
        /**
        * 
        * @param {String} visa | will be either h1b-visa or greencard
        * @param {Number} year | year to filter by eg: 2020
        * @param {String} cat | cat will be the type of the chart cat's : job-title, employer-city, employer-state
        */
        topSponsors: (visa, year) => {
            const visaType = visa === 'greencard' ? 'greencard' : 'h1b-visa';
            return `/${visaType}-top-sponsors-${year}-by-all/`
        },
        topSponsorsByCategory: (visa, year, cat) => {
            const visaType = visa === 'greencard' ? 'greencard' : 'h1b-visa';
            return cat == 'company' ? `/${visaType}-top-sponsors-${year}-overall/` : `/${visaType}-top-sponsors-${year}-by-${cat}/`;
        },
        getCaseDetails: (caseNumber, year, type) => {
            if (type == "greencard") {
                return `/greencard-sponsors/view-gc-case-details/?caseNumber=${caseNumber}&year=${year}`;    
            }
            return `/h1b-visa-sponsors/view-h1b-case-details/?caseNumber=${caseNumber}&year=${year}`;
        },
        employerCounts: '/api/sponsors/employer-counts/',
        addClaimCompany: '/api/add-claim-company-profile/',
        addContact: '/api/company-profiles/add-contact/',
        resendEmailVerification: '/api/resend-verification-code-company-claim/',
        conslidateAddress: '/api/sponsors/consolidate-address/',
        conslidateCompanies: '/api/sponsors/merge-companies/',
        adminCompProfiles: '/api/company-profiles/get-company-details/',
        getCompanyByName: '/api/company-profiles/search-companies/?companyName=',
        approveRequest: '/api/company-profiles/approve-request/',
        denyRequest: '/api/company-profiles/process-request/',
        approveDenyCompanyClaim: '/api/company-profiles/approved-denied-company-claim/',
        withdrawCompanyClaim: '/api/withdrawn-company-profile-claim/',
        manageReviews: '/api/manage-company-profile/manage-reviews/',
        getEmailByCompany: '/api/sponsors/employer-email/'
    },
    tracker: {
        getFieldsList: '/api/tracker/fieldList/',
        getComments: '/api/tracker/reply/',
        myProfileDelete: '/api/tracker/delete-tracker/',
        addFavorites: '/api/tracker/add-favorites/'
    },
    myprofile: {
        getCount: '/api/get-user-count/'
    },
    general: {
        uspsValidate: '/api/company-profiles/validate-address/'
    },
    userInterest: '/user-interests/'
};

export const RedirectsUrls = {
    login: immiObj.path.login,
    experienceLanding: prependUrl,
    travelCompanion: '/travel-companion/'
}

export const SessionKeys = {
    experience: {
        'openAddExperience': 'openAddExperience',
        'openTravelCompanion': 'openTravelCompanion'
    },
    travelCompanion: {
        'fromAirportCode': 'fromAirportCode',
        'toAirportCode': 'toAirportCode',
        'airlineObj': 'airlineObj',
    },
    babyName: {
        'addFavorites': 'addFavorites',
        'babyNames': 'babyNames'
    },
    vb: {
        'editTable': 'editTable',
        'forumLogin': 'forumLogin'
    },
    admin: { // Delete all session entries while login
        'experienceSection': 'experienceSection',
        'experienceSearchFields': 'experienceSearchFields',
        'companionSearchForm': 'companionSearchForm',
        'companionSearch': 'companionSearch',
        'allAirports': 'allAirports',
        'allAirlines': 'allAirlines',
        'trackerSearchFields': 'trackerSearchFields',
        'employerSearch': 'employerSearch',
        'companyProfiles': 'companyProfiles'
    },
    citizenshipCalc: {
        'progress': 'progress',
        'saveCitizenshipCalc': 'saveCitizenshipCalc'
    },
    blog: {
        'openAddBlog': 'openAddBlog'
    },
    tracker: {
        'viewType': 'viewType',
        'toggleColumns': 'toggleColumns',
        'openAddTracker': 'openAddTracker',
        'searchFields': 'searchFields',
        'quickFilters': 'quickFilters'
    },
    sponsors: {
        'openAddExperience': 'sponsorsAddExperience',
        'searchResultView': 'search-result-view',
        'topChartType': 'top-chart-type'
    }
};