export const AllAds = {
    "VisitorsToUSA": {
        quote: {
            "image": "/assets/inline-ads/visit-usa-1.jpg",
            "title": "Visitors Insurance",
            "link": "https://www.insubuy.com/visitors-insurance/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=visitor"
        },
        articles: [
            {
                "image": "/assets/inline-ads/visitors-insurance/claims-procedure-faq-travel-medical-insurance.jpg",
                "title": "Claims Process Overview",
                "link": "https://www.insubuy.com/international-health-insurance-claims/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=visitor1"
            }, {
                "image": "/assets/inline-ads/visitors-insurance/visitor-insurance-acute-onset-of-pre-existing-conditions.jpg",
                "title": "Pre Existing Conditions",
                "link": "https://www.insubuy.com/visitor-insurance-acute-onset-of-pre-existing-conditions/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=visitor2"
            }, {
                "image": "/assets/inline-ads/visitors-insurance/why-purchase-from-insubuy.jpg",
                "title": "Why Purchase from Insubuy?",
                "link": "https://www.insubuy.com/why-purchase-from-insubuy/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=visitor3"
            }, {
                "image": "/assets/inline-ads/visitors-insurance/visitor-medical-insurance-overview.jpg",
                "title": "Visitors Insurance Overview",
                "link": "https://www.insubuy.com/visitor-medical-insurance-overview/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=visitor4"
            }, {
                "image": "/assets/inline-ads/visitors-insurance/buying-travel-insurance-from-usa-vs-home-country.jpg",
                "title": "Buying from US vs Abroad",
                "link": "https://www.insubuy.com/buying-travel-insurance-from-usa-vs-home-country/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=visitor5"
            }
        ]
    },

    "TravelOutside": {
        quote: {
            "image": "/assets/inline-ads/visit-usa-2.jpg",
            "title": "Travel Insurance Excluding USA",
            "link": "https://www.insubuy.com/travel-health-insurance/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=businessvisa"
        },
        articles: [{
                "image": "/assets/inline-ads/travel/importance-of-travel-insurance-for-an-international-travel.jpg",
                "title": "Importance of Travel Insurance",
                "link": "https://www.insubuy.com/importance-of-travel-insurance-for-an-international-travel/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=traveloutsideusa1"
            },
            {
                "image": "/assets/inline-ads/travel-outside-usa-1.jpg",
                "title": "Travel Insurance Outside USA",
                "link": "https://www.insubuy.com/travel-health-insurance/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=traveloutsideusa"
            },
            {
                "image": "/assets/inline-ads/travel/single-trip-vs-multi-trip-travel-insurance.jpg",
                "title": "Single Trip vs. Multi Trip Travel Insurance",
                "link": "https://www.insubuy.com/single-trip-vs-multi-trip-travel-insurance/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=traveloutsideusa3"
            },
            {
                "image": "/assets/inline-ads/travel/road-trip-travel-insurance.jpg",
                "title": "Road Trip Travel Insurance",
                "link": "https://www.insubuy.com/road-trip-travel-insurance/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=traveloutsideusa4"
            }
        ]
    },

    "StudentVisa": {
        quote: {
            "image": "/assets/inline-ads/quote-form/international-student-insurance.jpg",
            "title": "Student Visa Insurance",
            "link": "https://www.insubuy.com/international-student-insurance/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=studentvisa"
        },
        articles: [{
                "image": "/assets/inline-ads/student/international-student-health-insurance-usa.jpg",
                "title": "Student Insurance Overview",
                "link": "https://www.insubuy.com/international-student-health-insurance-usa/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=studentvisa1"
            },
            {
                "image": "/assets/inline-ads/student/f2-visa-insurance.jpg",
                "title": "F2 Visa Insurance",
                "link": "https://www.insubuy.com/f2-visa-insurance/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=studentvisa2"
            },
            {
                "image": "/assets/inline-ads/student/f1-visa-insurance.jpg",
                "title": "F1 Visa Insurance",
                "link": "https://www.insubuy.com/f1-visa-insurance/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=studentvisa3"
            },
            {
                "image": "/assets/inline-ads/student/f1-student-insurance-requirements.jpg",
                "title": "Student Insurance Requirements",
                "link": "https://www.insubuy.com/f1-student-insurance-requirements/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=studentvisa4"
            },
            {
                "image": "/assets/inline-ads/student/international-student-health.jpg",
                "title": "Student Insurance FAQ",
                "link": "https://www.insubuy.com/international-student-health/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=studentvisa5"
            },
        ]
    },

    "SchengenVisa": {
        quote: {
            "image": "/assets/inline-ads/schengen/schengen-visa-2.jpg",
            "title": "Schengen Visa Insurance",
            "link": "https://www.insubuy.com/schengen-visa-insurance/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=schengen"
        },
        articles: [
            {
                "image": "/assets/inline-ads/schengen/europe-travel-insurance.jpg",
                "title": "Europe Travel Insurance",
                "link": "https://www.insubuy.com/europe-travel-insurance/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=schengen1"
            }, {
                "image": "/assets/inline-ads/schengen/schengen-insurance.jpg",
                "title": "Schengen Insurance Requirements",
                "link": "https://www.insubuy.com/schengen-insurance/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=schengen2"
            }, {
                "image": "/assets/inline-ads/schengen/insurance-for-schengen-visa.jpg",
                "title": "Schengen Insurance FAQ",
                "link": "https://www.insubuy.com/insurance-for-schengen-visa/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=schengen3"
            }, {
                "image": "/assets/inline-ads/schengen/french-visa-insurance.jpg",
                "title": "France Visa Insurance",
                "link": "https://www.insubuy.com/french-visa-insurance/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=schengen4"
            }, {
                "image": "/assets/inline-ads/schengen/spain-visa-travel-insurance.jpg",
                "title": "Spain Visa Insurance",
                "link": "https://www.insubuy.com/spain-visa-travel-insurance/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=schengen5"
            }, {
                "image": "/assets/inline-ads/schengen/german-visa-travel-insurance.jpg",
                "title": "Germany Visa Insurance",
                "link": "https://www.insubuy.com/german-visa-travel-insurance/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=schengen6"
            }, {
                "image": "/assets/inline-ads/schengen/italian-visa-travel-insurance.jpg",
                "title": "Italy Visa Insurance",
                "link": "https://www.insubuy.com/italian-visa-travel-insurance/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=schengen7"
            }, {
                "image": "/assets/inline-ads/schengen/switzerland-visa-travel-insurance.jpg",
                "title": "Switzerland Visa Insurance",
                "link": "https://www.insubuy.com/switzerland-visa-travel-insurance/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=schengen8"
            },
        ]
    },

    "NewImmigrant": {
        quote: {
            "image": "/assets/inline-ads/quote-form/new-immigrant-insurance.jpg",
            "title": "New Immigrants Insurance",
            "link": "https://www.insubuy.com/new-immigrant-insurance/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=newimmigrant"
        },
        articles: [{
                "image": "/assets/inline-ads/new-immigrants/new-immigrants-medical-insurance-plans.jpg",
                "title": "Insurance Overview",
                "link": "https://www.insubuy.com/new-immigrants-medical-insurance-plans/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=newimmigrant1"
            },
            {
                "image": "/assets/inline-ads/new-immigrants/medicare-for-new-immigrants-green-card-holders.jpg",
                "title": "Medicare Eligibility",
                "link": "https://www.insubuy.com/medicare-for-new-immigrants-green-card-holders/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=newimmigrant2"
            },
            {
                "image": "/assets/inline-ads/new-immigrants/greencard-holders-medical-insurance.jpg",
                "title": "Greencard Holder Insurance",
                "link": "https://www.insubuy.com/greencard-holders-medical-insurance/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=newimmigrant3"
            },
        ]
    },

    "MultiTrip": {
        quote: {
            "image": "/assets/inline-ads/quote-form/multi-trip-travel-insurance.jpg",
            "title": "Multitrip Insurance Quotes",
            "link": "https://www.insubuy.com/multi-trip-travel-insurance/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=multitrip"
        },
        articles: [{
            "image": "/assets/inline-ads/multi-trip/annual-travel-insurance.jpg",
            "title": "Annual Travel Insurance",
            "link": "https://www.insubuy.com/annual-travel-insurance/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=multitrip1"
        }]
    },

    "GroupTravel": {
        quote: {
            "image": "/assets/inline-ads/group-travel/group_travel_medical.jpg",
            "title": "Group Travel Insurance",
            "link": "https://www.insubuy.com/group-travel-medical-insurance/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=grouptravel"
        },
        articles: [{
                "image": "/assets/inline-ads/group-travel/group-travel-insurance.jpg",
                "title": "Group Insurance Overview",
                "link": "https://www.insubuy.com/group-travel-insurance/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=grouptravel1"
            },
            {
                "image": "/assets/inline-ads/group-travel/j1-visa-group.jpg",
                "title": "J1 Visa Group Insurance",
                "link": "https://www.insubuy.com/j1-visa-group-medical-insurance/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=grouptravel2"
            },
            {
                "image": "/assets/inline-ads/group-travel/international-group-medical-insurance.jpg",
                "title": "Long Term Group Insurance",
                "link": "https://www.insubuy.com/international-group-medical-insurance/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=grouptravel3"
            },
            {
                "image": "/assets/inline-ads/group-travel/traveler-insurance.jpg",
                "title": "International Travel Insurance",
                "link": "https://www.insubuy.com/traveler-insurance/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=grouptravel4"
            },
            {
                "image": "/assets/inline-ads/group-travel/what-is-travel-insurance.jpg",
                "title": "What is Travel Insurance?",
                "link": "https://www.insubuy.com/what-is-travel-insurance/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=grouptravel5"
            },
        ]
    },

    "ExchangeVisitor": {
        quote: {
            "image": "/assets/inline-ads/exchange-visitor/j1-j2-visa-1.jpg",
            "title": "J1 Visa Insurance",
            "link": "https://www.insubuy.com/j1-visa-health-insurance/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=j1visa"
        },
        articles: [{
                "image": "/assets/inline-ads/exchange-visitor/exchange-visitor-insurance.jpg",
                "title": "J1 Visa Insurance Requirements",
                "link": "https://www.insubuy.com/exchange-visitor-insurance/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=j1visa1"
            },
            {
                "image": "/assets/inline-ads/exchange-visitor/j1-visa-insurance-faq.jpg",
                "title": "J1 Visa Insurance FAQ",
                "link": "https://www.insubuy.com/j1-visa-insurance-faq/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=j1visa2"
            },
            {
                "image": "/assets/inline-ads/group-travel/j1-visa-group.jpg",
                "title": "J1 Visa Group Insurance",
                "link": "https://www.insubuy.com/j1-visa-group-medical-insurance/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=j1visa3"
            },
            {
                "image": "/assets/inline-ads/exchange-visitor/aca-requirements-international-students.jpg",
                "title": "ACA Requirements and Exemptions",
                "link": "https://www.insubuy.com/aca-requirements-international-students/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=j1visa4"
            },
            {
                "image": "/assets/inline-ads/exchange-visitor/j1-visa-compliant-insurance.jpg",
                "title": "J1 Visa Compliance Form",
                "link": "https://www.insubuy.com/j1-visa-compliant-insurance/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=j1visa5"
            },
            {
                "image": "/assets/inline-ads/exchange-visitor/medical-evacuation-and-repatriation-insurance.jpg",
                "title": "Evacuation and Repatriation",
                "link": "https://www.insubuy.com/medical-evacuation-and-repatriation-insurance/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=j1visa6"
            },
        ]
    },

    "BusinessVisa": {
        quote: { // reusing visitors insurance
            "image": "/assets/inline-ads/visit-usa-1.jpg",
            "title": "Visitors Insurance",
            "link": "https://www.insubuy.com/visitors-insurance/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=businessvisa"
        },
        articles: [
            {
                "image": "/assets/inline-ads/group-travel/international-travel.jpg",
                "title": "Travel Insurance Overview",
                "link": "https://www.insubuy.com/business-visa-health-insurance/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=businessvisa2"
            },
            {
                "image": "/assets/inline-ads/business/business-travel-insurance.jpg",
                "title": "Business Travel Insurance",
                "link": "https://www.insubuy.com/business-travel-insurance/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=businessvisa1"
            }
        ],
    },

    "Flight": {
        quote: { // reusing visitors insurance
            "image": "/assets/inline-ads/quote-form/flight-insurance.jpg",
            "title": "Flight Insurance",
            "link": "https://www.insubuy.com/flight-insurance/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=flight"
        },
        articles: [{
                "image": "/assets/inline-ads/group-travel/what-is-travel-insurance.jpg",
                "title": "What is flight insurance?",
                "link": "https://www.insubuy.com/what-is-flight-insurance/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=flight1"
            }
        ],
    },

    "TripCancellation": {
        quote: { // reusing visitors insurance
            "image": "/assets/inline-ads/quote-form/travel-insurance.jpg",
            "title": "Travel Insurance",
            "link": "https://www.insubuy.com/travel-insurance/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=tripCancellation"
        },
        articles: [{
                "image": "/assets/inline-ads/group-travel/what-is-travel-insurance.jpg",
                "title": "What is travel insurance?",
                "link": "https://www.insubuy.com/what-is-travel-insurance/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=tripCancellation1"
            },
            {
                "image": "/assets/inline-ads/trip/is-travel-insurance-cancellable-and-refundable.jpg",
                "title": "Is Travel Insurance Cancellable and Refundable?",
                "link": "https://www.insubuy.com/is-travel-insurance-cancellable-and-refundable/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=tripCancellation2"
            },
            {
                "image": "/assets/inline-ads/trip/trip-cancellation-vs-trip-interruption-coverage-in-travel-insurance.jpg",
                "title": "Trip cancellation vs trip interruption",
                "link": "https://www.insubuy.com/trip-cancellation-vs-trip-interruption-coverage-in-travel-insurance/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=tripCancellation3"
            },
            {
                "image": "/assets/inline-ads/travel/road-trip-travel-insurance.jpg",
                "title": "Road Trip Travel Insurance",
                "link": "https://www.insubuy.com/road-trip-travel-insurance/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=travel"
            },
            {
                "image": "/assets/inline-ads/travel/importance-of-travel-insurance-for-an-international-travel.jpg",
                "title": "Importance of Travel Insurance",
                "link": "https://www.insubuy.com/importance-of-travel-insurance-for-an-international-travel/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=travel"
            }
        ]
    },

    "TravelCompanion": {
        general: [{ // reusing visitors insurance
            "image": "/assets/inline-ads/tools/travel-companion-en.jpg",
            "title": "Find Travel Companion",
            "link": "/travel-companion/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=travelcompanion",
            "linkText": "Travel Companion"
        }]
    },

    "FianceBusinessPreEx": {
        general: [{ // reusing visitors insurance
            "image": "/assets/inline-ads/fiance-visa-medical-insurance.jpg",
            "title": "Fiance Visa Insurance",
            "link": "https://www.insubuy.com/fiance-visa-medical-insurance/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=fiance",
            "linkText": "Read Article"
        },
        {
            "image": "/assets/inline-ads/business/business-travel-insurance.jpg",
            "title": "Business Travel Insurance",
            "link": "https://www.insubuy.com/buying-travel-insurance-from-usa-vs-home-country/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=visitor5",
            "linkText": "Read Article"
        },
        {
            "image": "/assets/inline-ads/visitors-insurance/visitor-insurance-acute-onset-of-pre-existing-conditions.jpg",
            "title": "Pre-Existing Conditions",
            "link": "https://www.insubuy.com/visitor-medical-insurance-overview/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=visitor4",
            "linkText": "Read Article"
        }]
    },

    "IndiaTravelInsurance": {
        general: [{
            "image": "/assets/inline-ads/india-travel-insurance.jpg",
            "title": "India Travel Insurance",
            "link": "https://www.insubuy.com/india-travel-insurance/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=visitor",
            "linkText": "Learn More"
        }]
    },

    "FrenchVisaInsurance": {
        general: [{
            "image": "/assets/inline-ads/schengen/europe-travel-insurance.jpg",
            "title": "French Visa Insurance",
            "link": "https://www.insubuy.com/french-visa-insurance/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=visitor",
            "linkText": "Learn More"
        }]
    }
}

export const AllAdsZH = {
    "VisitorsToUSA": {
        quote: {
            "image": "/assets/inline-ads/visit-usa-1.jpg",
            "title": "旅游保险",
            "link": "https://www.insubuy.com/meiguo-lvyou-yiliao-baoxian/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=visitor_zh"
        },
        articles: [{
            "image": "/assets/inline-ads/visitors-insurance/claims-procedure-faq-travel-medical-insurance.jpg",
            "title": "索赔流程",
            "link": "https://www.insubuy.com/guoji-jiankang-baoxian-lipei/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=visitor_zh1"
        }, {
            "image": "/assets/inline-ads/visitors-insurance/lvyou-baoxian-yuanyou-jibing-jixing-fazuo.jpg",
            "title": "原有疾病",
            "link": "https://www.insubuy.com/lvyou-baoxian-yuanyou-jibing-jixing-fazuo/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=visitor_zh2"
        }, {
            "image": "/assets/inline-ads/visitors-insurance/weihe-xuanze-insubuy.jpg",
            "title": "为什么选择Insubuy?",
            "link": "https://www.insubuy.com/why-purchase-from-insubuy/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=visitor_zh3"
        }, {
            "image": "/assets/inline-ads/visitors-insurance/visitor-medical-insurance-overview.jpg",
            "title": "旅游保险概述",
            "link": "https://www.insubuy.com/lvyou-yiliao-baoxian-gaishu/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=visitor_zh4"
        }, {
            "image": "/assets/inline-ads/visitors-insurance/buying-travel-insurance-from-usa-vs-home-country.jpg",
            "title": "购买美国保险 & 本国保险",
            "link": "https://www.insubuy.com/goumai-meiguo-yu-benguo-lvyou-baoxian/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=visitor_zh5"
        }]
    },

    "TravelOutside": {
        quote: {
            "image": "/assets/inline-ads/visit-usa-2.jpg",
            "title": "美国境外旅游",
            "link": "https://www.insubuy.com/lvyou-jiankang-baoxian/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=traveloutsideusa_zh"
        },
        articles: [{
                "image": "/assets/inline-ads/travel/importance-of-travel-insurance-for-an-international-travel.jpg",
                "title": "旅游保险的重要性",
                "link": "https://www.insubuy.com/lvxing-baoxian-dui-guoji-lvxing-zhongyaoxing/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=traveloutsideusa_zh1"
            },
            {
                "image": "/assets/inline-ads/travel-outside-usa-1.jpg",
                "title": "美国境外旅游医疗保险",
                "link": "https://www.insubuy.com/lvyou-jiankang-baoxian/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=traveloutsideusa_zh"
            },
            {
                "image": "/assets/inline-ads/travel/dancheng-yu-duocheng-lvxing-baoxian.jpg",
                "title": "单程和多程旅行保险",
                "link": "https://www.insubuy.com/dancheng-yu-duocheng-lvxing-baoxian/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=traveloutsideusa_zh2"
            },
            {
                "image": "/assets/inline-ads/travel/road-trip-travel-insurance.jpg",
                "title": "公路旅程保险",
                "link": "https://www.insubuy.com/gonglu-lvxing-lvyou-baoxian/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=traveloutsideusa_zh3"
            }
        ]
    },

    "StudentVisa": {
        quote: {
            "image": "/assets/inline-ads/quote-form/international-student-insurance.jpg",
            "title": "学生医疗保险",
            "link": "https://www.insubuy.com/guoji-xuesheng-baoxian/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=studentvisa_zh"
        },
        articles: [{
                "image": "/assets/inline-ads/student/guoji-xuesheng-jiankang-baoxian.jpg",
                "title": "国际学生保险概述",
                "link": "https://www.insubuy.com/guoji-xuesheng-jiankang-baoxian/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=studentvisa_zh1"
            },
            {
                "image": "/assets/inline-ads/student/f2-qianzheng-baoxian.jpg",
                "title": "F2 签证保险",
                "link": "https://www.insubuy.com/f2-qianzheng-baoxian/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=studentvisa_zh2"
            },
            {
                "image": "/assets/inline-ads/student/f1-qianzheng-baoxian.jpg",
                "title": "F1 签证保险",
                "link": "https://www.insubuy.com/f1-qianzheng-baoxian/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=studentvisa_zh3"
            },
            {
                "image": "/assets/inline-ads/student/f1-student-insurance-requirements.jpg",
                "title": "F1 学生保险要求",
                "link": "https://www.insubuy.com/f1-xuesheng-baoxian-yaoqiu/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=studentvisa_zh4"
            },
            {
                "image": "/assets/inline-ads/student/international-student-health.jpg",
                "title": "常见问题",
                "link": "https://www.insubuy.com/guoji-xuesheng-jiankang/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=studentvisa_zh5"
            },
        ]
    },

    "SchengenVisa": {
        quote: {
            "image": "/assets/inline-ads/schengen/schengen-visa-2.jpg",
            "title": "申根签证",
            "link": "https://www.insubuy.com/shengen-qianzheng-baoxian/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=schengen_zh"
        },
        articles: [{
            "image": "/assets/inline-ads/schengen/europe-travel-insurance.jpg",
            "title": "欧洲旅游保险",
            "link": "https://www.insubuy.com/ouzhou-lvyou-baoxian/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=schengen_zh1"
        }, {
            "image": "/assets/inline-ads/schengen/schengen-insurance.jpg",
            "title": "申根签证医疗保险概述",
            "link": "https://www.insubuy.com/schengen-qianzheng-baoxian/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=schengen_zh2"
        }, {
            "image": "/assets/inline-ads/schengen/insurance-for-schengen-visa.jpg",
            "title": "申根签证保险常问问题",
            "link": "https://www.insubuy.com/baoxian-schengen-qianzheng/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=schengen_zh3"
        }, {
            "image": "/assets/inline-ads/schengen/french-visa-insurance.jpg",
            "title": "法国签证旅游",
            "link": "https://www.insubuy.com/faguo-qianzheng-baoxian/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=schengen_zh4"
        }, {
            "image": "/assets/inline-ads/schengen/spain-visa-travel-insurance.jpg",
            "title": "西班牙签证旅游",
            "link": "https://www.insubuy.com/xibanya-qianzheng-lvyou-baoxian/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=schengen_zh5"
        }, {
            "image": "/assets/inline-ads/schengen/german-visa-travel-insurance.jpg",
            "title": "德国签证旅游",
            "link": "https://www.insubuy.com/deguo-qianzheng-lvyou-baoxian/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=schengen_zh6"
        }, {
            "image": "/assets/inline-ads/schengen/italian-visa-travel-insurance.jpg",
            "title": "意大利签证旅游",
            "link": "https://www.insubuy.com/yidali-qianzheng-lvyou-baoxian/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=schengen_zh7"
        }, {
            "image": "/assets/inline-ads/schengen/switzerland-visa-travel-insurance.jpg",
            "title": "瑞士签证旅游",
            "link": "https://www.insubuy.com/ruishi-qianzheng-lvyou-baoxian/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=schengen_zh8"
        }, ]
    },

    "NewImmigrant": {
        quote: {
            "image": "/assets/inline-ads/quote-form/new-immigrant-insurance.jpg",
            "title": "新移民健康保险",
            "link": "https://www.insubuy.com/xin-yimin-yiliao-baoxian/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=newimmigrant_zh"
        },
        articles: [{
                "image": "/assets/inline-ads/new-immigrants/xin-yimin-yiliao-baoxian-chanpin.jpg",
                "title": "新移民健康保险概述",
                "link": "https://www.insubuy.com/xin-yimin-yiliao-baoxian-chanpin/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=newimmigrant_zh1"
            },
            {
                "image": "/assets/inline-ads/new-immigrants/xinyimin-lvka-chiyouzhe-medicare.jpg",
                "title": "Medicare 对新移民要求",
                "link": "https://www.insubuy.com/xinyimin-lvka-chiyouzhe-medicare/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=newimmigrant_zh2"
            },
            {
                "image": "/assets/inline-ads/new-immigrants/lvka-chiyouzhe-yiliao-baoxian.jpg",
                "title": "绿卡持有者医疗保险常问问题",
                "link": "https://www.insubuy.com/lvka-chiyouzhe-yiliao-baoxian/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=newimmigrant_zh3"
            },
        ]
    },

    "MultiTrip": {
        quote: {
            "image": "/assets/inline-ads/quote-form/multi-trip-travel-insurance.jpg",
            "title": "多程旅行",
            "link": "https://www.insubuy.com/multi-trip-travel-insurance/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=multitrip_zh"
        },
        articles: [{
            "image": "/assets/inline-ads/multi-trip/annual-travel-insurance.jpg",
            "title": "年度旅行保险",
            "link": "https://www.insubuy.com/niandu-lvxing-baoxian/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=multitrip_zh1"
        }]
    },

    "GroupTravel": {
        quote: {
            "image": "/assets/inline-ads/group-travel/group_travel_medical.jpg",
            "title": "团体旅游",
            "link": "https://www.insubuy.com/tuanti-lvyou-yiliao-baoxian/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=grouptravel_zh"
        },
        articles: [{
                "image": "/assets/inline-ads/group-travel/group-travel-insurance.jpg",
                "title": "团体旅行保险",
                "link": "https://www.insubuy.com/tuanti-lvyou-baoxian/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=grouptravel_zh1"
            },
            {
                "image": "/assets/inline-ads/exchange-visitor/j1-qianzheng-tuanti-yiliao-baoxian.jpg",
                "title": "J1 签证团体医疗保险",
                "link": "https://www.insubuy.com/j1-qianzheng-tuanti-yiliao-baoxian/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=grouptravel_zh2"
            },
            {
                "image": "/assets/inline-ads/group-travel/guoji-tuanti-yiliao-baoxian.jpg",
                "title": "国际长期团体保险-概括",
                "link": "https://www.insubuy.com/guoji-tuanti-yiliao-baoxian/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=grouptravel_zh3"
            },
            {
                "image": "/assets/inline-ads/group-travel/traveler-insurance.jpg",
                "title": "国际旅行医疗保险",
                "link": "https://www.insubuy.com/lvke-baoxian/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=grouptravel_zh4"
            },
            {
                "image": "/assets/inline-ads/group-travel/what-is-travel-insurance.jpg",
                "title": "什么是行程保险?",
                "link": "https://www.insubuy.com/hewei-xingcheng-baoxian/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=grouptravel_zh5"
            },
        ]
    },

    "ExchangeVisitor": {
        quote: {
            "image": "/assets/inline-ads/exchange-visitor/j1-j2-visa-1.jpg",
            "title": "交流访问学者 (J1/J2)",
            "link": "https://www.insubuy.com/j1-qianzheng-baoxian/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=j1visa_zh"
        },
        articles: [{
                "image": "/assets/inline-ads/exchange-visitor/jiaoliu-fangwen-xuezhe-baoxian.jpg",
                "title": "J1 签证保险",
                "link": "https://www.insubuy.com/jiaoliu-fangwen-xuezhe-baoxian/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=j1visa_zh1"
            },
            {
                "image": "/assets/inline-ads/exchange-visitor/j1-qianzheng-baoxian-changjianwenti.jpg",
                "title": "J1签证保险常见问题",
                "link": "https://www.insubuy.com/j1-qianzheng-baoxian-changjianwenti/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=j1visa_zh2"
            },
            {
                "image": "/assets/inline-ads/group-travel/j1-qianzheng-tuanti-yiliao-baoxian.jpg",
                "title": "J1 签证团体医疗险",
                "link": "https://www.insubuy.com/j1-qianzheng-tuanti-yiliao-baoxian/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=j1visa_zh3"
            },
            {
                "image": "/assets/inline-ads/exchange-visitor/aca-requirements-international-students.jpg",
                "title": "ACA 要求与免除",
                "link": "https://www.insubuy.com/guoji-xuesheng-aca-yaoqiu/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=j1visa_zh4"
            },
            {
                "image": "/assets/inline-ads/exchange-visitor/j1-visa-compliant-insurance.jpg",
                "title": "符合要求表格",
                "link": "https://www.insubuy.com/j1-qianzheng-fuheyaoqiu-baoxian/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=j1visa_zh5"
            },
            {
                "image": "/assets/inline-ads/exchange-visitor/medical-evacuation-and-repatriation-insurance.jpg",
                "title": "独立医疗运送",
                "link": "https://www.insubuy.com/yiliao-shusan-he-yitisongfan-baoxian/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=j1visa_zh6"
            },
        ]
    },

    "BusinessVisa": {
        quote: { // reusing visitors insurance
            "image": "/assets/inline-ads/visit-usa-1.jpg",
            "title": "美国境内旅游",
            "link": "https://www.insubuy.com/meiguo-lvyou-yiliao-baoxian/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=businessvisa_zh"
        },
        articles: [{
                "image": "/assets/inline-ads/business/business-visa-health-insurance.jpg",
                "title": "商务签证医疗保险",
                "link": "https://www.insubuy.com/shangwu-qianzheng-yiliao-baoxian/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=businessvisa_zh2"
            },
            {
                "image": "/assets/inline-ads/business/shangwu-lvxing-baoxian.jpg",
                "title": "商务旅行保险",
                "link": "https://www.insubuy.com/shangwu-lvxing-baoxian/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=businessvisa_zh1"
            }
        ],
    },

    "Flight": {
        quote: { // reusing visitors insurance
            "image": "/assets/inline-ads/quote-form/flight-insurance.jpg",
            "title": "飞行事故",
            "link": "https://www.insubuy.com/flight-insurance/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=flight_zh"
        },
        articles: [{
            "image": "/assets/inline-ads/group-travel/what-is-travel-insurance.jpg",
            "title": "飞行事故保险",
            "link": "https://www.insubuy.com/hewei-feixing-baoxian/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=flight_zh1"
        }],
    },

    "TripCancellation": {
        quote: { // reusing visitors insurance
            "image": "/assets/inline-ads/quote-form/travel-insurance.jpg",
            "title": "旅程保险",
            "link": "https://www.insubuy.com/travel-insurance/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=tripCancellation_zh"
        },
        articles: [{
                "image": "/assets/inline-ads/group-travel/what-is-travel-insurance.jpg",
                "title": "什么是旅行保险?",
                "link": "https://www.insubuy.com/hewei-xingcheng-baoxian/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=tripCancellation_zh1"
            },
            {
                "image": "/assets/inline-ads/trip/is-travel-insurance-cancellable-and-refundable.jpg",
                "title": "旅行程保险是否可以申请取消并退款?",
                "link": "https://www.insubuy.com/lvcheng-baoxian-shifou-keyi-shenqing-quxiao-tuikuan/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=tripCancellation_zh2"
            },
            {
                "image": "/assets/inline-ads/trip/lvxing-baoxian-xingcheng-quxiao-yu-xingcheng-zhongduan.jpg",
                "title": "旅行保险行程取消和行程中断保障",
                "link": "https://www.insubuy.com/lvxing-baoxian-xingcheng-quxiao-yu-xingcheng-zhongduan/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=tripCancellation_zh3"
            },
            {
                "image": "/assets/inline-ads/travel/road-trip-travel-insurance.jpg",
                "title": "自驾旅游保险",
                "link": "https://www.insubuy.com/gonglu-lvxing-lvyou-baoxian/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=travel_zh"
            },
            {
                "image": "/assets/inline-ads/travel/importancia-del-seguro-de-viaje-para-un-viaje-internacional.jpg",
                "title": "La importancia del seguro de viaje",
                "link": "https://www.insubuy.com/importancia-del-seguro-de-viaje-para-un-viaje-internacional/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=travel_es"
            }
        ]
    },

    "TravelCompanion": {
        general: [{ // reusing visitors insurance
            "image": "/assets/inline-ads/tools/travel-companion-zh.jpg",
            "title": "寻找旅伴",
            "link": "/lvxing-banlv/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=travelcompanion_zh",
            "linkText": "旅伴"
        }]
    },

    "FianceBusinessPreEx": {
        general: [{ // reusing visitors insurance
            "image": "/assets/inline-ads/fiance-visa-medical-insurance.jpg",
            "title": "未婚夫/妻旅游保险",
            "link": "https://www.insubuy.com/weihunqi-qianzheng-yiliao-baoxian/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=fiance_zh",
            "linkText": "阅读文章"
        },
        {
            "image": "/assets/inline-ads/business/shangwu-lvxing-baoxian.jpg",
            "title": "商务旅游保险",
            "link": "https://www.insubuy.com/shangwu-lvxing-baoxian/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=businessvisa_zh1",
            "linkText": "阅读文章"
        },
        {
            "image": "/assets/inline-ads/visitors-insurance/lvyou-baoxian-yuanyou-jibing-jixing-fazuo.jpg",
            "title": "原有疾病",
            "link": "https://www.insubuy.com/lvyou-baoxian-yuanyou-jibing-jixing-fazuo/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=visitor_zh2",
            "linkText": "阅读文章"
        }]
    },

    "FrenchVisaInsurance": {
        general: [{
            "image": "/assets/inline-ads/schengen/europe-travel-insurance.jpg",
            "title": "法国签证保险",
            "link": "https://www.insubuy.com/faguo-qianzheng-baoxian/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=visitor_zh",
            "linkText": "了解更多"
        }]
    }
}

export const AllAdsES = {
    "VisitorsToUSA": {
        quote: {
            "image": "/assets/inline-ads/visit-usa-1.jpg",
            "title": "Seguro de visitantes",
            "link": "https://www.insubuy.com/seguro-medico-para-visitantes-a-los-estados-unidos/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=visitor_es"
        },
        articles: [
            {
                "image": "/assets/inline-ads/visitors-insurance/claims-procedure-faq-travel-medical-insurance.jpg",
                "title": "Descripción general del proceso de reclamos",
                "link": "https://www.insubuy.com/reclamos-de-seguro-de-salud-internacional/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=visitor_es1"
            }, {
                "image": "/assets/inline-ads/visitors-insurance/seguro-de-visitantes-inicio-agudo-de-condiciones-preexistentes.jpg",
                "title": "Pre Existing Conditions",
                "link": "https://www.insubuy.com/seguro-de-visitantes-inicio-agudo-de-condiciones-preexistentes/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=visitor_es2"
            }, {
                "image": "/assets/inline-ads/visitors-insurance/why-purchase-from-insubuy.jpg",
                "title": "¿Por que comprar de Insubuy?",
                "link": "https://www.insubuy.com/por-que-comprar-de-insubuy/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=visitor_es3"
            }, {
                "image": "/assets/inline-ads/visitors-insurance/visitor-medical-insurance-overview.jpg",
                "title": "Descripción general del seguro para visitantes",
                "link": "https://www.insubuy.com/seguro-medico-de-visitante-descripcion-general/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=visitor_es4"
            }, {
                "image": "/assets/inline-ads/visitors-insurance/comprar-seguro-de-viaje-de-eeuu-vs-pais-de-origen.jpg",
                "title": "Comprar de los EEUU vs país de origen",
                "link": "https://www.insubuy.com/comprar-seguro-de-viaje-de-eeuu-vs-pais-de-origen/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=visitor_es5"
            }
        ]
    },

    "TravelOutside": {
        quote: {
            "image": "/assets/inline-ads/visit-usa-2.jpg",
            "title": "Seguro de viaje fuera de EE.UU.",
            "link": "https://www.insubuy.com/seguro-de-salud-de-viaje/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=traveloutsideusa_es"
        },
        articles: [{
                "image": "/assets/inline-ads/travel/importancia-del-seguro-de-viaje-para-un-viaje-internacional.jpg",
                "title": "La importancia del seguro de viaje",
                "link": "https://www.insubuy.com/importancia-del-seguro-de-viaje-para-un-viaje-internacional/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=traveloutsideusa_es1"
            },
            {
                "image": "/assets/inline-ads/travel-outside-usa-1.jpg",
                "title": "Seguro de viaje fuera de EEUU",
                "link": "https://www.insubuy.com/seguro-de-salud-de-viaje/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=traveloutsideusa_es"
            },
            {
                "image": "/assets/inline-ads/travel/single-trip-vs-multi-trip-travel-insurance.jpg",
                "title": "Seguro de Viaje Único vs. Multi Viaje",
                "link": "https://www.insubuy.com/seguro-de-viaje-unico-vs-multi-viaje/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=traveloutsideusa_es3"
            },
            {
                "image": "/assets/inline-ads/travel/road-trip-travel-insurance.jpg",
                "title": "Seguro de viaje por carretera",
                "link": "https://www.insubuy.com/seguro-de-viaje-por-carretera/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=traveloutsideusa_es4"
            }
        ]
    },

    "StudentVisa": {
        quote: {
            "image": "/assets/inline-ads/quote-form/international-student-insurance.jpg",
            "title": "Seguro de visa de estudiante",
            "link": "https://www.insubuy.com/seguro-de-estudiante-internacional/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=studentvisa_es"
        },
        articles: [{
                "image": "/assets/inline-ads/student/international-student-health-insurance-usa.jpg",
                "title": "Resumen del seguro de estudiante",
                "link": "https://www.insubuy.com/seguro-de-salud-para-estudiante-internacional-eeuu/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=studentvisa_es1"
            },
            {
                "image": "/assets/inline-ads/student/f2-visa-insurance.jpg",
                "title": "Seguro de visa F2",
                "link": "https://www.insubuy.com/seguro-visa-f2/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=studentvisa_es2"
            },
            {
                "image": "/assets/inline-ads/student/f1-visa-insurance.jpg",
                "title": "Seguro de visa F1",
                "link": "https://www.insubuy.com/seguro-visa-f1/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=studentvisa_es3"
            },
            {
                "image": "/assets/inline-ads/student/f1-student-insurance-requirements.jpg",
                "title": "Requisitos para seguro de estudiantes",
                "link": "https://www.insubuy.com/requisitos-de-seguro-de-estudiante-f1/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=studentvisa_es4"
            },
            {
                "image": "/assets/inline-ads/student/international-student-health.jpg",
                "title": "Preguntas frecuentes sobre seguro estudiantil",
                "link": "https://www.insubuy.com/salud-de-estudiante-internacional/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=studentvisa_es5"
            },
        ]
    },

    "SchengenVisa": {
        quote: {
            "image": "/assets/inline-ads/schengen/schengen-visa-2.jpg",
            "title": "Seguro de Visa Schengen",
            "link": "https://www.insubuy.com/seguro-para-visado-schengen/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=schengen_es"
        },
        articles: [
            {
                "image": "/assets/inline-ads/schengen/europe-travel-insurance.jpg",
                "title": "Seguro de viaje a Europa",
                "link": "https://www.insubuy.com/seguro-de-viaje-a-europa/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=schengen_es1"
            }, {
                "image": "/assets/inline-ads/schengen/schengen-insurance.jpg",
                "title": "Requisitos del seguro Schengen",
                "link": "https://www.insubuy.com/seguro-schengen/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=schengen_es2"
            }, {
                "image": "/assets/inline-ads/schengen/insurance-for-schengen-visa.jpg",
                "title": "Preguntas frecuentes sobre el seguro Schengen",
                "link": "https://www.insubuy.com/seguro-para-visa-schengen/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=schengen_es3"
            }, {
                "image": "/assets/inline-ads/schengen/french-visa-insurance.jpg",
                "title": "Seguro del visado Francia",
                "link": "https://www.insubuy.com/seguro-de-visa-frances/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=schengen_es4"
            }, {
                "image": "/assets/inline-ads/schengen/spain-visa-travel-insurance.jpg",
                "title": "Seguro de visa España",
                "link": "https://www.insubuy.com/seguro-de-viaje-para-visa-espana/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=schengen_es5"
            }, {
                "image": "/assets/inline-ads/schengen/german-visa-travel-insurance.jpg",
                "title": "Seguro del visado Alemania",
                "link": "https://www.insubuy.com/seguro-de-viaje-para-visa-alemania/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=schengen_es6"
            }, {
                "image": "/assets/inline-ads/schengen/italian-visa-travel-insurance.jpg",
                "title": "Seguro de visa Italia",
                "link": "https://www.insubuy.com/seguro-de-viaje-para-visa-italiana/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=schengen_es7"
            }, {
                "image": "/assets/inline-ads/schengen/switzerland-visa-travel-insurance.jpg",
                "title": "Seguro del visado Suiza",
                "link": "https://www.insubuy.com/seguro-de-viaje-para-visa-suiza/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=schengen_es8"
            },
        ]
    },

    "NewImmigrant": {
        quote: {
            "image": "/assets/inline-ads/quote-form/new-immigrant-insurance.jpg",
            "title": "Seguro para nuevos inmigrantes",
            "link": "https://www.insubuy.com/seguro-medico-para-inmigrantes-nuevos/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=newimmigrants_es"
        },
        articles: [{
                "image": "/assets/inline-ads/new-immigrants/new-immigrants-medical-insurance-plans.jpg",
                "title": "Resumen del seguro para inmigrantes nuevos",
                "link": "https://www.insubuy.com/planes-de-seguro-medico-para-nuevos-inmigrantes/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=newimmigrant_es1"
            },
            {
                "image": "/assets/inline-ads/new-immigrants/medicare-for-new-immigrants-green-card-holders.jpg",
                "title": "Elegibilidad para Medicare",
                "link": "https://www.insubuy.com/medicare-para-nuevos-inmigrantes-titulares-de-tarjeta-verde/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=newimmigrant_es2"
            },
            {
                "image": "/assets/inline-ads/new-immigrants/greencard-holders-medical-insurance.jpg",
                "title": "Seguro para titulares de tarjeta verde",
                "link": "https://www.insubuy.com/seguro-medico-para-titulares-de-tarjeta-verde/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=newimmigrant_es3"
            },
        ]
    },

    "MultiTrip": {
        quote: {
            "image": "/assets/inline-ads/quote-form/multi-trip-travel-insurance.jpg",
            "title": "Cotizaciones de seguro multiviaje",
            "link": "https://www.insubuy.com/multi-trip-travel-insurance/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=multitrip_es"
        },
        articles: [{
            "image": "/assets/inline-ads/multi-trip/annual-travel-insurance.jpg",
            "title": "Seguro de viaje anual",
            "link": "https://www.insubuy.com/seguro-de-viaje-anual/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=multitrip_es1"
        }]
    },

    "GroupTravel": {
        quote: {
            "image": "/assets/inline-ads/group-travel/group_travel_medical.jpg",
            "title": "Seguro de viaje grupal",
            "link": "https://www.insubuy.com/seguro-medico-de-viaje-grupal/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=grouptravel_es"
        },
        articles: [{
                "image": "/assets/inline-ads/group-travel/seguro-de-viaje-grupal.jpg",
                "title": "Descripción general del seguro grupal",
                "link": "https://www.insubuy.com/seguro-de-viaje-grupal/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=grouptravel_es1"
            },
            {
                "image": "/assets/inline-ads/group-travel/j1-visa-group.jpg",
                "title": "Seguro grupal para visado J1",
                "link": "https://www.insubuy.com/seguro-medico-grupal-de-visa-j1/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=grouptravel_es2"
            },
            {
                "image": "/assets/inline-ads/group-travel/international-group-medical-insurance.jpg",
                "title": "Seguro de viaje grupal a largo plazo",
                "link": "https://www.insubuy.com/seguro-medico-grupal-internacional/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=grouptravel_es3"
            },
            {
                "image": "/assets/inline-ads/group-travel/traveler-insurance.jpg",
                "title": ">Seguro de viaje internacional",
                "link": "https://www.insubuy.com/seguro-de-viajero/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=grouptravel_es4"
            },
            {
                "image": "/assets/inline-ads/group-travel/what-is-travel-insurance.jpg",
                "title": "¿Que es el seguro de viaje?",
                "link": "https://www.insubuy.com/que-es-el-seguro-de-viaje/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=grouptravel_es5"
            },
        ]
    },

    "ExchangeVisitor": {
        quote: {
            "image": "/assets/inline-ads/exchange-visitor/j1-j2-visa-1.jpg",
            "title": "Seguro del visado J1",
            "link": "https://www.insubuy.com/seguro-j1-visa/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=j1visa_es"
        },
        articles: [{
                "image": "/assets/inline-ads/exchange-visitor/seguro-de-visitante-de-intercambio.jpg",
                "title": "Requisitos de seguro para visa J1",
                "link": "https://www.insubuy.com/seguro-de-visitante-de-intercambio/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=j1visa_es"
            },
            {
                "image": "/assets/inline-ads/exchange-visitor/seguro-de-visa-j1-preguntas-frecuentes.jpg",
                "title": "Preguntas frecuentes sobre seguro para visado J1",
                "link": "https://www.insubuy.com/seguro-de-visa-j1-preguntas-frecuentes/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=j1visa_es2"
            },
            {
                "image": "/assets/inline-ads/group-travel/j1-visa-group.jpg",
                "title": "Seguro grupal de visa J1",
                "link": "https://www.insubuy.com/seguro-medico-grupal-de-visa-j1/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=j1visa_es3"
            },
            {
                "image": "/assets/inline-ads/exchange-visitor/aca-requirements-international-students.jpg",
                "title": "Requisitos y exención de la ley ACA",
                "link": "https://www.insubuy.com/requisitos-de-aca-estudiantes-internacionales/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=j1visa_es4"
            },
            {
                "image": "/assets/inline-ads/exchange-visitor/j1-visa-compliant-insurance.jpg",
                "title": "Formulario de cumplimiento para visado J1",
                "link": "https://www.insubuy.com/seguro-conforme-con-la-visa-j1/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=j1visa_es5"
            },
            {
                "image": "/assets/inline-ads/exchange-visitor/medical-evacuation-and-repatriation-insurance.jpg",
                "title": "Evacuación y repatriación",
                "link": "https://www.insubuy.com/evacuacion-medica-y-seguro-de-repatriacion/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=j1visa_es6"
            },
        ]
    },

    "BusinessVisa": {
        quote: { // reusing visitors insurance
            "image": "/assets/inline-ads/visit-usa-1.jpg",
            "title": "Seguro de visitantes",
            "link": "https://www.insubuy.com/seguro-medico-para-visitantes-a-los-estados-unidos/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=businessvisa_es"
        },
        articles: [
            {
                "image": "/assets/inline-ads/business/business-visa-health-insurance.jpg",
                "title": "Resumen del seguro de viaje",
                "link": "https://www.insubuy.com/seguro-medico-de-visa-de-negocios/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=businessvisa_es2"
            },
            {
                "image": "/assets/inline-ads/business/seguro-de-viaje-de-negocios.jpg",
                "title": "Seguro de viaje de negocios",
                "link": "https://www.insubuy.com/seguro-de-viaje-de-negocios/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=businessvisa_es1"
            }
        ],
    },

    "Flight": {
        quote: { // reusing visitors insurance
            "image": "/assets/inline-ads/quote-form/flight-insurance.jpg",
            "title": "Seguro de vuelo",
            "link": "https://www.insubuy.com/flight-insurance/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=flight_es"
        },
        articles: [{
                "image": "/assets/inline-ads/group-travel/what-is-travel-insurance.jpg",
                "title": "¿Qué es el seguro de vuelo?",
                "link": "https://www.insubuy.com/que-es-seguro-de-vuelo/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=flight_es1"
            }
        ],
    },

    "TripCancellation": {
        quote: { // reusing visitors insurance
            "image": "/assets/inline-ads/quote-form/travel-insurance.jpg",
            "title": "Seguro de viaje",
            "link": "https://www.insubuy.com/travel-insurance/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=tripCancellation_es"
        },
        articles: [{
                "image": "/assets/inline-ads/group-travel/what-is-travel-insurance.jpg",
                "title": "¿Qué es el seguro de viaje?",
                "link": "https://www.insubuy.com/que-es-el-seguro-de-viaje/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=tripCancellation_es1"
            },
            {
                "image": "/assets/inline-ads/trip/is-travel-insurance-cancellable-and-refundable.jpg",
                "title": "¿Es el seguro de viaje cancelable y reembolsable?",
                "link": "https://www.insubuy.com/el-seguro-de-viaje-es-cancelable-y-reembolsable/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=tripCancellation_es2"
            },
            {
                "image": "/assets/inline-ads/travel/cancelacion-de-viaje-vs-cobertura-de-interrupcion-de-viaje-en-seguro-de-viaje.jpg",
                "title": "Cancelación de viaje vs interrupción de viaje",
                "link": "https://www.insubuy.com/cancelacion-de-viaje-vs-cobertura-de-interrupcion-de-viaje-en-seguro-de-viaje/?utm_source=immihelp&amp;utm_medium=inlinead&amp;utm_campaign=tripCancellation_es3"
            },
            {
                "image": "/assets/inline-ads/travel/road-trip-travel-insurance.jpg",
                "title": "Seguro de viaje por carretera",
                "link": "https://www.insubuy.com/seguro-de-viaje-por-carretera/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=travel_es"
            }
        ]
    },

    "TravelCompanion": {
        general: [{ // reusing visitors insurance
            "image": "/assets/inline-ads/tools/travel-companion-es.jpg",
            "title": "Encuentre un compañero de viaje",
            "link": "/travel-companion/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=travelcompanion_es",
            "linkText": "Compañero de viaje"
        }]
    },

    "FianceBusinessPreEx": {
        general: [{ // reusing visitors insurance
            "image": "/assets/inline-ads/fiance-visa-medical-insurance.jpg",
            "title": "Seguro para visa de novios",
            "link": "https://www.insubuy.com/weihunqi-qianzheng-yiliao-baoxian/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=fiance_zh",
            "linkText": "Lea el artículo"
        },
        {
            "image": "/assets/inline-ads/business/seguro-de-viaje-de-negocios.jpg",
            "title": "Seguro de viaje de negocios",
            "link": "https://www.insubuy.com/shangwu-lvxing-baoxian/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=businessvisa_zh1",
            "linkText": "Lea el artículo"
        },
        {
            "image": "/assets/inline-ads/visitors-insurance/seguro-de-visitantes-inicio-agudo-de-condiciones-preexistentes.jpg",
            "title": "Condiciones preexistentes",
            "link": "https://www.insubuy.com/lvyou-baoxian-yuanyou-jibing-jixing-fazuo/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=visitor_zh2",
            "linkText": "Lea el artículo"
        }]
    },

    "FrenchVisaInsurance": {
        general: [{
            "image": "/assets/inline-ads/schengen/europe-travel-insurance.jpg",
            "title": "Seguro para la visa francesa",
            "link": "https://www.insubuy.com/seguro-de-viaje-para-visa-francesa/?utm_source=immihelp&utm_medium=inlinead&utm_campaign=visitor_es",
            "linkText": "Más información"
        }]
    }
}

/**
 * All Insurance Types
 * 
 VisitorsToUSA
 TravelOutside
 StudentVisa
 SchengenVisa
 NewImmigrant
 MultiTrip
 GroupTravel
 ExchangeVisitor
 BusinessVisa
 Flight
 TripCancellation
 TravelCompanion
 FianceBusinessPreEx
 IndiaTravelInsurance (only EN)
 FrenchVisaInsurance
 */