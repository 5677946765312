<template>
    <div class="loader" :class="cssClass" :style="styler">
        <div class="loader__blade"></div>
        <div class="loader__blade"></div>
        <div class="loader__blade"></div>
        <div class="loader__blade"></div>
        <div class="loader__blade"></div>
        <div class="loader__blade"></div>
        <div class="loader__blade"></div>
        <div class="loader__blade"></div>
        <div class="loader__blade"></div>
        <div class="loader__blade"></div>
        <div class="loader__blade"></div>
        <div class="loader__blade"></div>
    </div>
</template>

<script>
export default {
    name: 'spinner',
    props: ['isDark', 'position', 'fontSize', 'customClass'],  
    data () {
        return {

        }
    },
    computed: {
        cssClass() {
            const color = this.isDark ? 'loader--dark' : 'loader--light';
            const position = this.position === 'center' ? 'loader--center' : '';
            const customClass = this.customClass ? this.customClass : '';

            return `${color} ${position} ${customClass}`;
        },
        styler() {
          return `font-size: ${this.fontSize ? this.fontSize : '30px'}; height: ${this.fontSize ? this.fontSize : '30px'}; width: ${this.fontSize ? this.fontSize : '30px'}`;
        }
    }
}
</script>

<style lang="scss" scoped>
$spinner-color-light: white;
$spinner-color-dark: #0071bc;
$spinner-size: 30px;
$spinner-amount: 12;

.loader {
  display: inline-block;
  font-size: $spinner-size;
  height: $spinner-size;
  position: relative;
  vertical-align: middle;
  width: $spinner-size;

  &__blade {
    animation: loader-fade 1s infinite linear;
    background-color: $spinner-color-light;
    border-radius: .5em;
    bottom: 0;
    height: .2777em;
    left: .4629em;
    position: absolute;
    transform-origin: center -.2222em;
    width: .074em;

    $animation-delay: 0s;
    $blade-rotation: 0deg;

    @for $i from 1 through $spinner-amount {
      &:nth-child(#{$i}) {
        animation-delay: $animation-delay;
        transform: rotate($blade-rotation);
        $blade-rotation: $blade-rotation + (360 / $spinner-amount);
        $animation-delay: $animation-delay + (1 / $spinner-amount);
      }
    }
  }

  &--center {
    display: block;
    left: 50%;
    margin: auto;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &--dark .loader__blade {
    background-color: $spinner-color-dark;
  }
}

@keyframes loader-fade {
  0% {
    opacity: 1;
  }

  100% {
    opacity: .3;
  }
}

</style>