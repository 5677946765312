import { getEl } from '../../helpers/helpers';
import { breakpoints } from '../../globals/config';
// HOW TO USE \\
// {
//     id: ID OF ITEM WHICH HAS SHOW CLASS APPENDED,
//     clickId: ID OF ITEM WHICH MUST BE CLICKED ON DOM TO TRIGGER DISPLAY,
//     showClass: CSS CLASS WHICH TRIGGERS DISPLAY FROM NONE TO SOMETHING OTHER THAN NONE,
//     closeFunction: OPTIONAL FUNCTION TO HANDLE ADDITIONAL LOGIC FOR WHEN THE ELEMENT IS REMOVED
//     conditions: FUNCTION RETURNING FALSE IF SHOULD NOT CLICK AWAY. OPTIONAL AND DEFAULTS TO TRUE.
// },

export default [
    {
        id: 'share-abs-menu',
        clickId: 'share-link',
        showClass: 'c-Header-share-abs-wrap--show'
    },
    {
        id: 'mobile-menu',
        clickId: 'hamburger-menu',
        showClass: 'mobile-open',
        closeFunction: function() {
            getEl(this.clickId).classList.remove(this.showClass);
            window.mobileToggle = false;
        }
    }
];