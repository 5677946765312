<template>
    <div class="c-Snackbar">
        <transition name="slide-fade">
            <div v-if="show">
                <div class="c-Snackbar__content" :class="type">
                    <span v-html="showEmittedMessage ? emittedMessage : message"></span> 
                    <span class="c-Snackbar__close" @click="close">
                        <svg viewBox="0 0 20 20" class="o-Icon__svg o-Icon__svg--10 o-Icon__svg--close">
                            <use xlink:href="/assets/build/svg/defs/svg/sprite.defs.svg#close_icon" class="o-Icon__svg__inner">
                            </use>
                        </svg>
                    </span>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'snackbar',
    props: ['message', 'type'],
    data () {
        return {
            show: false,
            showEmittedMessage: false,
            emittedMessage: ''
        }
    },
    created () {
        this.$root.$on('openSnackbar', (message) => {
            this.emittedMessage = message;
            this.showEmittedMessage = true;
            this.open();
        })
    },
    methods: {
        open () {
            this.show = true;
            setTimeout(() => {
                this.close();
            }, 7000);
        },
        close () {
            this.show = false;
        }
    }
}
</script>

<style lang="scss" scoped>

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.c-Snackbar {
    position: fixed; left: 0px; z-index: 1000; width: 100%; padding: 0px calc(0.2rem); pointer-events: none; text-align: center; bottom: 1em;
}

.c-Snackbar__content {
    display: inline-block;
    width: auto;
    min-width: 1rem;
    max-width: calc(100vw - 0.4rem);
    padding: 1em;
    border-radius: 4px;
    line-height: calc(0.2rem);
    color: rgb(255, 255, 255);
    background: rgb(61, 189, 125);
    box-shadow: 0px 1px 11px 3px rgba(101, 101, 101, 0.3);    
    text-align: center;
    pointer-events: all;
    user-select: none;
    font-size: .875em;

    &.success {
        background: #187ec2;
        color: #fff;
        
        a {
            color: #fff;
        }
    }
}

.c-Snackbar__content {
    display: inline-flex !important;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
}

.c-Snackbar__close {
    display: block;
    width: 10px;
    height: 10px;
    margin: 0 0 0 .75em;
    cursor: pointer;
}
</style>