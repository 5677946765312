<template>
    <div class="o-Adv-block" :class="className">
        <div class="o-Adv-block__item" v-for="(ads, index) in allAds" :key="index" :class="{'o-Adv-block__item--two' : count == 2, 'u-Ma-b-0': index == (allAds.length -1)}" v-html="ads"></div>
        <div class="show-when-print o-Adv-block__middle" style="border-top:0;">
            <p>For visitors, travel, student and other international travel medical insurance.</p>
            <span>Visit insubuy.com or call +1 (866) INSUBUY or +1 (972) 985-4400</span>
        </div>
    </div>
</template>

<script>
import InlineAds from '../wordpress/components/inline-ads';
import {
    getRandomInt
} from '../helper-functions';

/**
 * list of sections
 *  VisitorsToUSA
    TravelOutside
    StudentVisa
    SchengenVisa
    NewImmigrant
    MultiTrip
    GroupTravel
    ExchangeVisitor
    BusinessVisa
    Flight
    TripCancellation
    TravelCompanion
    FianceBusinessPreEx
    IndiaTravelInsurance (only EN)
    FrenchVisaInsurance
 */

export default {
    name:'inlineAds',
    props: ['sections', 'count', 'className', 'addQuote'],
    data () {
        return {
            allAds: [],
            sectionIds: []
        }
    },
    created () {
        if(!this.sections || this.sections.length === 0) {
            this.sections = ['VisitorsToUSA'];
        }
        const randInt = getRandomInt(0, this.sections.length-1);
        let qutoeSection = this.sections[randInt];
        if(qutoeSection && qutoeSection == 'YoungAdult') qutoeSection = 'VisitorsToUSA';
        else if(qutoeSection == null) {
            qutoeSection = 'VisitorsToUSA';
        }
        const inlineAds = new InlineAds(qutoeSection, 'quote');
        this.allAds.push(inlineAds.advertisement);        
        if(!this.count) {
            this.getAds();
            if (this.addQuote) {
                const addQuoteAd = new InlineAds(this.addQuote, 'quote');
                this.allAds.push(addQuoteAd.advertisement);
            } else {
                this.getAds();
            }
        } else {
            for (let i = 0; i < +this.count-1; i++) {
                this.getAds();                
            }
        }

    },
    methods: {
        getAds () {
            let articleSection = this.sections[this.uniqueRandome()];
            if(articleSection == 'YoungAdult') articleSection = 'VisitorsToUSA';
            const inlineAds = new InlineAds(articleSection, 'articles');
            this.allAds.push(inlineAds.advertisement);
        },
        /**
         * Generates and returns an unique random number between 0 and the section length.
         */
        uniqueRandome () {
            let randArticleInt, i = 1;
            while(i > 0) {
                randArticleInt = getRandomInt(0, this.sections.length-1);                
                if(this.sections.length == 1 || this.sectionIds.indexOf(randArticleInt) == -1) {
                    i = 0;
                    break;
                }
            }
            this.sectionIds.push(randArticleInt);            
            return randArticleInt;
        }
    }

}
</script>

<style lang="scss" scoped>
    .o-Adv-block {
        padding-top: 0;
    }
</style>