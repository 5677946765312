<template>
    <div class=" o-Checkbox__wrapper">
        <label class="o-Form__fields-label o-Form__fields-label--checkbox" :class="{'o-Form__fields-label--checkbox-disabled': disable}">
        <input type="checkbox" :disabled=disable :checked="value" @change="$emit('input', $event.target.checked)" name="rememberMe" value="yes">
        <span class="checkmark"></span>
        <div class="slot">
            <slot></slot>
        </div>
    </label>
    </div>
</template>

<script>
export default {
    name: 'checkbox',
    props: ['value', 'disable']
}
</script>