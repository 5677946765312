export const ErrorCodes = {
    '500': 'Server Error',
    '404': 'Page Not Found',
    '002': 'Please do login or register.'
}

export const error = {
    required: function (field) { return `${window.immiLang.errorMessage.isRequired.replace('{0}', field)}`;},
    singleDate: function (field) {return `${window.immiLang.errorMessage.singleDate.replace('{0}', field)}`;},
    notInFuture: function (field) {return `${window.immiLang.errorMessage.notInFuture.replace('{0}', field)}`;},
    rangeDate: function (field) {return `${window.immiLang.errorMessage.rangeDate.replace('{0}', field)}`;},
    invalid: function (field) {return `${window.immiLang.errorMessage.invalid.replace('{0}', field)}`;},
}