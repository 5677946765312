<template>
    <div class="date-piacker-container o-Form__fields-input-icon-wrapper">
        <input type="text" class="o-Form-control" :id="id" :name="id" :placeholder="placeholder" @change="$emit('close');$emit('date-selected');" :value="formatedSelectedDate" @input="formatDate" @click="clickInputBox">
        <date-range-picker ref="picker"
            :locale-data="singleDateFormat"            
            :singleDatePicker="singleDatePicker"
            :timePicker="timePicker"
            :time-picker-increment="timePickerIncrement"
            :timePicker24Hour="false"
            :showDropdowns="showDropdowns"
            :always-show-calendars="true"
            :separator="separator"
            :autoApply="autoApply" v-model="dateChosen" 
            :maxDate="maxDate"
            :ranges="showRange == false ? false : ranges"
            @update="emitAfterSelected"
            :opens="opens"
            :dateFormat="dateFormat">
            <!-- <div slot="input" slot-scope="picker"
                style="min-width: 350px;">
                {{ picker.startDate | date }} -
                {{ picker.endDate | date }}
            </div> -->
        </date-range-picker>
    </div>
</template>

<script>
import Vue from 'vue';
import DateRangePicker from 'vue2-daterange-picker';

import * as moment from 'moment';
// import 'moment/locale/es';
// import 'moment/locale/zh-cn';
import * as momenttz from '../third-party/moment-timezone';
import '../third-party/moment-timezone-with-data';

const dateFormat = {
    en: immiLang?.currentLangCode == 'es' ? 'DD/MM/YYYY' : immiLang?.currentLangCode == 'zh' ? 'YYYY-MM-DD' : 'MM/DD/YYYY',
    labelTo: ` ${window.immiLang.datePicker.to} `
};

// moment.locale('en')

export default {
    name: 'datePicker',
    props: ['value', 'opens','minDate','maxDate','singleDatePicker','timePicker','timePicker24Hour','showWeekNumbers','showDropdowns','autoApply','linkedCalendars','dateFormat', 'placeholder', 'id', 'timezone', 'showRange', 'lang'],
    components: {
        DateRangePicker
    },
    data () {
        return {
            formatedSelectedDate: '',
            inputDate: '',
            dateChosen: {
                startDate: '',
                endDate: '',
            },
            separator: dateFormat.labelTo,
            startDate: '',
            endDate: '',
            timePickerIncrement: 1,
            ranges: {
                'Today': [moment().toDate(), moment().toDate()],
                'Yesterday': [moment().subtract(1, 'day')._d, moment().subtract(1, 'day')._d],
                'This Week': [moment().day(0).toDate(),moment().subtract(1, 'day')._d],
                'Month to Date': [moment().startOf('month'),moment().subtract(1, 'day')._d],
                'Year to Date' : [moment().startOf('year')._d,moment().subtract(1, 'day')._d],
                'TTM': [moment().subtract(12, 'months')._d, moment().subtract(1, 'day')._d]
            },
            timeout: null,
            singleDateFormat: { 
                format: window.immiLang.datePicker.singleFormat,
                monthNames: moment.monthsShort(),
                daysOfWeek: moment.weekdaysMin()
            }
        }
    },
    created () {
        this.singleDateFormat.monthNames = window.immiLang.monthNames.split(',');
        this.singleDateFormat.daysOfWeek = window.immiLang.daysOfWeek.split(',');
        if(this.value) {
            if(this.singleDatePicker && new Date(this.value) != "Invalid Date") {
                this.dateChosen.startDate = new Date(this.value).toISOString();
                this.dateChosen.endDate = new Date(this.value).toISOString();
                this.formatedSelectedDate = typeof this.value === "string" ? this.value.trim() : this.value;
            } else {
                try {                
                    const defaultStartDate = this.value.split(dateFormat.labelTo)[0];
                    const defaultEndDate = this.value.split(dateFormat.labelTo)[1];
                    this.dateChosen.startDate = new Date(defaultStartDate).toISOString();
                    this.dateChosen.endDate = new Date(defaultEndDate).toISOString();
                    this.formatedSelectedDate = this.value;
                } catch (e) {
                    this.dateChosen.startDate = "";
                    this.dateChosen.endDate = "";
                    this.formatedSelectedDate = this.value;
                }
            }
        } else {
            this.dateChosen.startDate = "";
            this.dateChosen.endDate = "";
            this.formatedSelectedDate = "";
        }
    },
    mounted () {

    },
    watch: {
        dateChosen: function () {

            if(this.dateChosen && this.dateChosen.startDate && this.dateChosen.endDate && !this.singleDatePicker) {
                const startDate = moment(this.dateChosen.startDate).format(dateFormat.en);
                const endDate = moment(this.dateChosen.endDate).format(dateFormat.en);
                this.formatedSelectedDate = startDate + dateFormat.labelTo + endDate;
                this.updateDate();
            } else if(this.singleDatePicker && this.dateChosen && this.dateChosen.startDate) {
                const startDate = moment(this.dateChosen.startDate).format(dateFormat.en);
                let timeSelected = '';
                if(this.timePicker) {
                    if(this.timezone === 'EDT') {
                        timeSelected = momenttz(this.dateChosen.startDate).tz('America/New_York').format("hh:mm A z");
                    } else {
                        timeSelected = momenttz(this.dateChosen.startDate).tz('America/Chicago').format("hh:mm A z");
                    }
                }
                this.formatedSelectedDate = startDate + ' ' + timeSelected;
                this.formatedSelectedDate = this.formatedSelectedDate.trim();
                this.updateDate();
            }
            
        },
        value: function () {
            if (this.value && !this.singleDatePicker) {
                let isValidDate = /^\d{1,2}\/\d{1,2}\/\d{4}\sto\s\d{1,2}\/\d{1,2}\/\d{4}$/g.test(this.value);
                if (isValidDate) {
                    let startDate = this.value.split(dateFormat.labelTo)[0];
                    let endDate = this.value.split(dateFormat.labelTo)[1];
                    let doubleDigitals = /^\d{2}\/\d{2}\/\d{4}/g;

                    if (!doubleDigitals.test(startDate) || !doubleDigitals.test(endDate)) {
                        let startMoment = moment(startDate);
                        let endMoment = moment(endDate);
                        if (startMoment.isValid() && endMoment.isValid()) {
                            startDate = startMoment.format(dateFormat.singleFormat);
                            endDate = endMoment.format(dateFormat.singleFormat);

                            this.formatedSelectedDate = startDate + dateFormat.labelTo + endDate;;
                            this.dateChosen.startDate = startDate;
                            this.dateChosen.endDate = endDate;
                            this.updateDate();
                        }
                    }
                }
            } else if (this.value && this.singleDatePicker) {
                let isValidDate = /^\d{2}\/\d{2}\/\d{4}$/g.test(this.value);
                if (isValidDate) {
                    let valueMoment = moment(new Date(this.value));
                    if(valueMoment.isValid()) {
                        this.formatedSelectedDate = this.value.trim();
                    }
                }
            } else {
                this.formatedSelectedDate = '';
                this.dateChosen.startDate = '';
                this.dateChosen.endDate = '';
            }
        }
    },
    methods: {
        clickInputBox: function() {
            // this.$refs.picker.togglePicker();
        },
        emitAfterSelected: function (e) {    
            if(e && e.startDate && e.endDate && this.singleDatePicker) {
                const startDate = moment(this.dateChosen.startDate).format(dateFormat.en);
                let timeSelected;
                if(this.timePicker && e.startDate) {
                    const hr = $(".hourselect").val();
                    const min = $(".minuteselect").val();
                    const ampm = $(".ampmselect").val();
                    const thisDate = new Date(startDate + ' ' + hr + ':' + min + ':' + ampm ).toISOString();
                    if(this.timezone === 'EDT') {
                        timeSelected = momenttz(thisDate).tz('America/New_York').format("hh:mm A z");
                    } else {
                        timeSelected = momenttz(thisDate).tz('America/Chicago').format("hh:mm A z");
                    }
                    e.startDate = new Date(thisDate);
                    e.endDate = new Date(thisDate);
                    this.dateChosen.startDate = new Date(thisDate.trim());
                    this.dateChosen.endDate = new Date(thisDate.trim());
                }                

            }
            this.$emit('date-selected', e);            
        },
        updateDate: function () {
            this.$emit('input', this.formatedSelectedDate.trim());
            this.$root.$emit('global-date-change', this.formatedSelectedDate.trim(), this.id);
        },
        formatDate: function (e) {
            if (this.timeout) clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {                
                let value = e.target.value;
                const regex = /^\d{2}\/\d{2}\/\d{4}\sto\s\d{2}\/\d{2}\/\d{4}$/;
                const regexSingle = /^\d{2}\/\d{2}\/\d{4}\s\d{2}:\d{2}\s(AM|PM)\s\w{3}$/;
                let isValidDate = false;
                if(regex.test(value) && !this.singleDatePicker) {
                    const splitdate = value.split(' to ');
                    const startDate = momenttz(splitdate[0], dateFormat.en);
                    const endDate = momenttz(splitdate[1], dateFormat.en);
                    isValidDate = startDate._isValid && endDate._isValid;
                    if(isValidDate) {
                        this.dateChosen.startDate = new Date(splitdate[0]).toISOString();
                        this.dateChosen.endDate = new Date(splitdate[1]).toISOString();
                    }
                } else if (this.singleDatePicker && regexSingle.test(value)) {
                    const ampm = value.indexOf('AM') > -1 ? ' AM ' : ' PM ';
                    value = new Date(value.replace(ampm,' '));
                    const startDate = moment(value).format(dateFormat.en);
                    let timeSelected;
                    if(this.timePicker) {
                        if(this.timezone === 'EDT') {
                            timeSelected = moment(value).tz('America/New_York').format("hh:mm A z");
                        } else {
                            timeSelected = moment(value).tz('America/Chicago').format("hh:mm A z");
                        }
                    }
                    value = startDate + ' ' + timeSelected;
                } else {
                    isValidDate = false;
                }
                this.formatedSelectedDate = value.trim();
                this.updateDate();
            }, 750);
        }
    }
}
</script>

<style lang="scss">    
    
    .o-Form__fields-input-icon-wrapper {
        width: 100%;
    }

    .calendar-time select {
        background: white !important;
        padding: .2em 1.2em !important;
        text-align: center;
    }

    .daterangepicker.single .ranges {
        display: none;
    }

    .daterangepicker.single .calendars {
        grid-template-columns: 100%;
    }    

    .yearselect {
        width: 50px;
    }

    .vue-daterange-picker {
        min-width: auto;
    }
    
</style>
