'use strict';
import Vue from 'vue';
import VueResource from 'vue-resource';
import 'babel-polyfill';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
// Vue.config.devtools = true
import {
    IgnoreErrors,
    IgnoreUrls,
    WhiteListUrls    
} from './helper-functions/sentry-config';
require('./wordpress/modules/clickAway/clickAway');
import {
    Spinner
} from 'spin.js';
import * as moment from 'moment';
import {
    mixin as clickaway
} from 'vue-clickaway';

import {
    handleCopyCut,
    getItemSessionStorage,
    deleteSessionStore,
    SessionKeys,
    ApiUrls
} from './helper-functions'

global.eventHub = new Vue();
global.jQuery = require('jquery');
global.$ = global.jQuery;
require('./helper-functions/email-protect-spam');
Vue.use(VueResource);

__webpack_public_path__ = '/assets/build/js/'+window.immiLang.buildNumber+'/';

Sentry.init({
    dsn: 'https://7ceab690391047d18b21c5e0fe862f89@o164316.ingest.sentry.io/5241221',
    integrations: [new VueIntegration({Vue, attachProps: true})],
    whitelistUrls: WhiteListUrls,
    blacklistUrls: IgnoreUrls,
    ignoreErrors: IgnoreErrors,
    shouldSendCallback: function (data) {
        return !/^(.*CloudFlare-AlwaysOnline.*|.*Opera Mini.*|.*UC Browser.*|.*Yandex Browser.*|.+MSIE 8\.0;.+)$/.test(window.navigator.userAgent);
    }
});

if (!GlobalVue) {
    GlobalVue = new Vue({
        el: '#globalVue',
        data: function () {
            return {
                showUserProfile: false
            }
        },
        watch: {
            
        },
        mounted () {
            $(".c-Admin-site #hamburger-menu").click((e) => {
                $("#globalVue.c-Admin-site__nav").toggleClass("show");
                $(e.currentTarget).toggleClass("mobile-open");
            })
        },
        methods: {
            away () {
                this.showUserProfile = false;
            },
            showProfile () {
                this.showUserProfile = !this.showUserProfile;
            }
        }
    });
}

Vue.component('drop-down', {
    mixins: [clickaway],
    props: ['language'],
    data () {
        return {
            isDropDownOpen: false
        }
    },
    methods: {
        away() {
            this.isDropDownOpen = false;
        },
        toggleDropDown(e) {
            this.isDropDownOpen = !this.isDropDownOpen;
        },
        selectSection (param) {
            this.$emit('clicked', param);
            this.away();
        }
    }
})

window.injectEmail = function () {
    $('.js-E-rev').each(function () {
        $(this).ibEProtect();
    });
};

export const MainVueOpts = {
    data: function () {
        return {
            currentLang: window.immiObj ? window.immiObj.currentLanguage ? window.immiObj.currentLanguage : 'English' : 'English',
            globalErrors: [],
            fieldErrors: [],
            server500ErrorShown: false,
            serverErrors: [],
            debounceResize: null,            
        }
    },
    created: function () {
       
    },
    mounted: function () {
        const $breadcurmbs = document.querySelectorAll("#breadcrumbs a[data-href]");
        if($breadcurmbs) {
            [...$breadcurmbs].forEach(item => {
                const href = item.getAttribute('data-href');
                item.href = href;
            });
        }
        // pagination
        let isPagination = document.querySelectorAll('.o-Post-nav__pagination') || [];
        if(isPagination) isPagination = Array.from(isPagination);
        if(isPagination && isPagination.length) {
            window.addEventListener('resize', e => {
                /**
                 $bp - xtra - small: 375 px;
                 $bp - small: 544 px;
                 $bp - medium: 768 px;
                 $bp - large: 992 px;
                 $bp - xtra - large: 1260 px;
                 $bp - super - large: 1500 px;
                 */
                clearTimeout(this.debounceResize);
                this.debounceResize = setTimeout(() => {
                    this.resizePagination();
                },250);
            });
            this.resizePagination();
        }

        // Global Logout for Forum
        let logoutBtn = document.getElementById('cred-logout');
        let mobLogoutBtn = document.getElementById('cred-logout-mob');
        if (logoutBtn) {
            logoutBtn.addEventListener('click', (e) => {this.logoutFn(e);});
        } else if (mobLogoutBtn) {
            mobLogoutBtn.addEventListener('click', (e) => {this.logoutFn(e);});
        }
    },
    methods: {
        setBackendErrors: function (errors) {
            this.fieldErrors = [];
            this.globalErrors = [];

            if (errors.fieldErrors) {
                this.fieldErrors = errors.fieldErrors;
            }
            if (errors.globalErrors) {
                this.globalErrors = errors.globalErrors;
            }
        },
        showServer500Error: function (errors) {
            if (errors && errors.serverErrors) {
                this.serverErrors = errors.serverErrors;
            }
            try {
                this.server500ErrorShown = true;
            } catch (e) {}

            setTimeout(function () {
                $('.js-E-rev').ibEProtect();
            }, 1000);
        },
        resizePagination: function () {
            const innerWidth = window.innerWidth;
            let isPagination = document.querySelectorAll('.o-Post-nav__pagination') || [];
            if (isPagination) isPagination = Array.from(isPagination);
            isPagination.forEach(item => {
                const children = Array.from(item.children);
                const chilLen = children.length;
                const isLarge = children.length === 14;
                const isSmall = children.length <= 7;
                if (innerWidth <= 544) {
                    if(isLarge) {
                        children[1].style.display = 'none';                        
                        [7, 8, 9, 10, 11, 12].forEach(i => children[i].style.display = 'none');
                    } else if (!isLarge && !isSmall) {
                        children.forEach((el, i) => {
                            if (i != 0 && i != (chilLen - 1) && i > 5) {
                                children[i].style.display = 'none';
                            }
                        })
                    }
                } else if (innerWidth > 544 && innerWidth <= 768) {
                    if (isLarge) {
                        children[1].style.display = '';
                        children[12].style.display = '';
                        [7, 8, 9, 10, 11].forEach(i => children[i].style.display = 'none');
                    } else if (!isLarge && !isSmall) {
                        children.forEach((el, i) => {
                            if (i != 0 && i != (chilLen - 1) && i > 5) {
                                children[i].style.display = '';
                            }
                        })
                    }
                } else {
                    if (isLarge) {
                        children[1].style.display = '';
                        [7, 8, 9, 10, 11, 12].forEach(i => children[i].style.display = '');
                    }
                }
            })            
        },
        logoutFn: function (e) {
            e.preventDefault();
                let forumCookie = getItemSessionStorage(SessionKeys.vb.forumLogin) || null;
                if(forumCookie) {
                    this.$http.get('/forum/auth/logout?logouthash='+forumCookie).then(res => {
                        // logout from forum
                        deleteSessionStore(SessionKeys.vb.forumLogin);
                        Vue.nextTick(() => {
                            window.location.href = ApiUrls.login.logout;
                        })
                    }, error => {
                        deleteSessionStore(SessionKeys.vb.forumLogin);
                        Vue.nextTick(() => {
                            window.location.href = ApiUrls.login.logout;
                        })
                    })
                } else {
                    Vue.nextTick(() => {
                        window.location.href = ApiUrls.login.logout;
                    })
                }
        }
    }
};

if (!MainVue) {
    MainVue = Vue.extend(MainVueOpts);
}

export default MainVue;
injectEmail();