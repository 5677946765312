export const IgnoreErrors = [
    'DataCloneError',
    'sessionStorage',
    /QuotaExceededError:.*/,
    /DataCloneError:.*/,
    /SecurityError:.*/,
    '__isVuelidateAsyncVm',
    'WeixinJSBridge ',
    '(0 , r.regex) is not a function',
    'Cludo is not defined',
    'vid_mate_check is not defined',
    'memory',
    "Cannot read property 'call' of undefined",
    'Blocked a frame with origin',
    'QuotaExceededError',
    'webpackJsonp',
    /\b(value)\b/g,
    'Attempted to assign to readonly property',
    /\b(google.*)\b/g,
    /Unexpected token.*/,
    /\b(substr.*)\b(undefined)/,
    'GetScreenshotBoundingBox',
    'Exception thrown and not caught',
    '\'Symbol\' is undefined',
    'Can\'t find variable: Symbol',
    'ResizeObserver loop limit exceeded',    
    'document.getElementsByTagName(\'video\')[0].webkitExitFullScreen',
    'pktAnnotationHighlighter',
    'Can\'t find variable: article',
    'Can\'t find variable: pktAnnotationHighlighter',
    'a[b].target.className.indexOf',
    'ResizeObserver loop completed with undelivered notifications.',
    'Can\'t find variable: webkit',
    '\'row\' is undefined', // IE Vue js compatibility issue
    'only one instance of babel-polyfill is allowed',
    'Can\'t find variable: _tfa',
    'can\'t redefine non-configurable property "userAgent"',
    'JSON.parse: unterminated string',
    'NotSupportedError: Window.print: Dialogs not enabled for this window', // Print preview is not supported on firefox. Error handled in headers.js while attempting to print.,
    'Non-Error promise rejection captured with keys',
    'Uncaught FirebaseError', // caused due to firebase issue.
    'This browser doesn\'t support the API\'s required to use the firebase SDK', // firebase not supported on few browsers
    'Cannot set property \'font\' of null',
    'ServiceWorker', // firebase issue ignore    
    'toastr is not defined', // firebase notification error ignore
    'firebase.messaging',
    'firebase.messaging is not a function',
    'firebase-messaging.js',
    'messaging', // firebase
    'readyToSendToken',
    'readyToSendToken is not defined',
    'firebase',
    'window.webkit.messageHandlers.selectedDebugHandler.postMessage',
    'Unexpected end of input',
    'The string did not match the expected pattern',
    'TransactionInactiveError',
    'This operation is not allowed.',    
    'SymBrowser_ModifyWindowOpenWithTarget',
    'window.webkit.messageHandlers.selectedTextHandler.postMessage',
    'slider',
    'Object(...)',
    'instantSearchSDKJSBridgeClearHighlight',
    'NS_ERROR_FAILURE: No error message',
    'undefined is not an object (evaluating \'e[t].call\')',
    'Cannot read properties of undefined (reading \'call\')',
    'SyntaxError: Failed to execute \'measure\' on \'Performance\'',
    /(SyntaxError: Failed to execute \'measure\' on \'Performance\')/,
];

export const IgnoreUrls = [
    //ignoring facebook urls if they are not able to load due to restrictions in certain countries
    /graph\.facebook\.com/i,
    /connect\.facebook\.net\/en_US\/all\.js/i,

    //ignoring google tag manager urls if they are not able to load due to restrictions in certain countries or blocked in the browser
    /www\.googletagmanager\.com/i,
    /\/(gtm|ga|analytics)\.js/i,
    /translate\.googleapis\.com/i,
    /googleads\.g\.doubleclick\.net/i,//ignore google ads issues
    /pagead2\.googlesyndication\.com/i,
    /stats\.g\.doubleclick\.net/i,
    // chrome errors

    /^chrome:\/\//i,
    /extensions\//i,

    // firefox errors

    /^resource:\/\//i,
    /\/assets\/build\/js\/visa-bulletin\.js/i,
    /\/assets\/js\/firebase-messaging\.js/i,
    /\/assets\/js\/firebase-app\.js/i
];

export const WhiteListUrls = [
    /https?:\/\/((www)\.)?immihelp\.com\/assets/
];

export const ShouldSendCallback = function (data) {
    return !/^(.*CloudFlare-AlwaysOnline.*|.*Opera Mini.*|.*UC Browser.*|.*Yandex Browser.*|.*Chrome Mobile WebView*.|.+MSIE 8\.0;.+)$/.test(window.navigator.userAgent);
}