<template>
  <transition name="modal">
    <div class="c-Main-modal" @click="closeOnClick" :class="parentClass ? parentClass : ''">
      <div class="c-Main-modal__wrapper">
        <div class="c-Main-modal__container" :class="footerBtmZero ? 'u-Pa-b-0' : ''">

          <div class="c-Main-modal__header">
            <slot name="header"><!-- Header is passed through slot --></slot>

            <a href="javascript:void(0)" v-show="showCloseBtn" :id="id ? id : 'modal-btn-close'" @click.prevent="$emit('close');" class="c-Main-model-header__close o-Icon-bullet o-Icon-bullet--close-blue"></a>
          </div>

          <div class="c-Main-modal__body">
            <slot name="body"><!-- Body is passed through slot --></slot>
          </div>

          <div class="c-Main-modal__footer">
            <slot name="footer">
              <!-- Footer is passed through slot -->
              <button class="o-Btn o-Btn--blue-drk u-Pa-lr-small" @click="$emit('close')">{{immiLang.globalLabels.cancel}}</button>
              <button class="o-Btn o-Btn--dark-red" @click="$emit('ok')" :disabled=disableOk>
                <slot name="okbtn">OK</slot>
                <spinner v-if="disableOk" :is-dark="'true'" :font-size="'10px'"></spinner>
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>


<script>
import Spinner from '../components/components.spinner.vue';
export default {
  name: 'modal',
  props: ['btnType', 'disableOk', 'isDefenition', 'footerBtmZero', 'parentClass', 'id', 'showClose'],
  components: {
    Spinner
  },
  data () {
    return {
      immiLang: window.immiLang || {},
      showCloseBtn: this.showClose != null ? this.showClose : true
    }
  },
  created () {
    document.querySelector('body').classList.add('is-Locked');
    document.addEventListener('keyup', this.closeModal);    
  },
  beforeDestroy () {
    document.querySelector('body').classList.remove('is-Locked');
  },
  watch: {
    showClose (val) {
      this.showCloseBtn = typeof val == 'boolean' || typeof val == 'string' ? val : true;
    }
  },
  methods: {
    closeModal (evt) {
        if (evt.keyCode === 27) {
            document.removeEventListener('keyup', this.closeModal);
            this.$emit('close');
        }
    },
    closeOnClick (evt) {
      if(this.isDefenition) {
        const isParent = $(evt.target).parent().closest('.c-Main-modal__wrapper');
        if(!isParent || isParent.length == 0) {
          document.removeEventListener('keyup', this.closeModal);
          this.$emit('close');
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.c-Main-modal__footer .o-Btn {
  margin-bottom: 1em;
  text-transform: uppercase;
  font-weight: 600;
  font-size: .875em;
  min-width: 120px;

  @media (min-width: 544px) {
    margin-bottom: 0;
  }
}

.o-Icon-bullet--close-blue:before {
  background-position: center;
  width: 1.5em;
  height: 1.5em;
}
</style>