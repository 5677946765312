const getEl = el => document.getElementById(el);
const getSelector = el => document.querySelector(el);

let generateRandomNumber = (start, end) =>  Math.floor(Math.random()*(end-start+1)+start);

Array.prototype.shuffleArray = function () {
	let currentIndex = this.length;
	let randomIndex, temporaryValue;

	while (0 != currentIndex) {
		randomIndex =  Math.floor(Math.random() * currentIndex);
		currentIndex -= 1;

		temporaryValue = this[currentIndex];
		this[currentIndex] = this[randomIndex];
		this[randomIndex] = temporaryValue;
	}
}

export { getEl, getSelector, generateRandomNumber };