import caConfig from './caConfig';
import {
    getEl
} from '../../helpers/helpers';


(function () {

    const untoggleView = el => {
        const element = getEl(el.id)
        const shouldShow = el.conditions ? el.conditions() : true;

        if (shouldShow && element) {
            element.classList.remove(el.showClass)
            if (el.closeFunction) {
                el.closeFunction();
            }
        }
    }

    document.addEventListener('click', e => {
        //caFilter and keepOpen check to ensure the element being clicked does not
        //close by checking if they have the same id. (x  => x.id !== keepOpen.id)
        if(e && e.target && typeof e.target.closest == 'function') {
            const caFilter = caConfig.filter(x => e.target.closest('#' + x.clickId) !== null).concat(caConfig.filter(x => e.target.closest('#' + x.id) !== null));
            const keepOpen = caFilter.length ? caFilter[0] : null;
            caConfig.forEach(x => keepOpen && x.id === keepOpen.id ? null : untoggleView(x));
        }

    });

})()