/**
 * Tracker date fields to check which has to validate dateNotInFutureSingleDate
 */
export const dateRangeValidation = {
    // Dates that appear here shoud not be in future.
    420561: ["documentsSentDate", "interviewDate", "statusDate", "passportReceivedDate"],
    420562: ["interviewDate", "statusDate", "passportReceivedDate"],
    420563: ["interviewDate", "statusDate", "passportReceivedDate"],
    420564: ["interviewDate", "statusDate", "passportReceivedDate"],
    420565: ["interviewDate", "statusDate", "passportReceivedDate"],
    420566: ["interviewDate", "documentSubmissionDate", "statusDate"],
    420567: ["applicationFiledDate", "checkCashedDate", "uscisReceiptDate", "uscisNoticeDate", "rfeReceivedDate", "rfeReplyDate", "statusDate", "mostRecentLUD"],
    420568: ["applicationFiledDate", "checkCachedDate", "uscisReceiptDate", "uscisNoticeDate", "rfeReceivedDate", "rfeReplyDate", "statusDate", "mostRecentLUD"],
    420569: ["travelToUSADate", "applicationFiledDate", "checkCashedDate", "uscisReceiptDate", "uscisNoticeDate", "rfeReceivedDate", "rfeReplyDate", "statusDate", "returnDate"],
    420570: ["applicationFiledDate", "checkCashedDate", "uscisReceiptDate", "rfeReceivedDate", "rfeReplyDate", "statusDate"],
    420571: ["auditReceivedDate", "auditReplyDate", "statusDate"],
    420572: ["applicationFiledDate", "checkCashedDate", "uscisReceiptDate", "uscisNoticeDate", "rfeReceivedDate", "rfeReplyDate", "interviewNoticeReceivedDate", "interviewDate"],
    420573: ["applicationFiledDate", "checkCashedDate", "uscisReceiptDate", "uscisNoticeDate", "rfeReceivedDate", "rfeReplyDate", "statusDate", "mostRecentLUD"],
    420574: ["applicationFiledDate", "applicationDeliveredDate", "checkCashedDate", "uscisReceiptDate", "uscisNoticeDate", "rfeReceivedDate", "rfeReplyDate", "interviewDate", "statusDate", "apReceivedDate", "mostRecentLUD"],
    420575: ["applicationFiledDate", "applicationDeliveredDate", "checkCashedDate", "uscisReceiptDate", "uscisNoticeDate", "rfeReceivedDate", "rfeReplyDate", "statusDate", "mostRecentLUD"],
    420576: ["applicationFiledDate", "applicationDeliveredDate", "checkCashedDate", "uscisReceiptDate", "uscisNoticeDate", "i140ApprovalDate", "rfeReceivedDate", "rfeReplyDate", "nameCheckStatusDate", "i485StatusDate", "interviewNoticeReceivedDate", "interviewDate", "cardOrderedDate", "cardReceiptDate", "eadApplicationDate", "eadApprovalDate", "apApplicationDate", "apApprovalDate", "mostRecentLud"],
    420577: ["i485ReceiptDate", "nbcCaseTransferDate", "interviewNoticeIssuedDate", "interviewNoticeReceiptDate"],
    420578: ["gcApprovalDate", "applicationFiledDate", "checkCachedDate", "uscisReceiptDate", "uscisNoticeDate", "rfeReceivedDate", "rfeReplyDate", "interviewNoticeRecdDate", "interviewDate", "fngrprintNoticeRecdDate", "statusDate"],
    420579: ["applicationFiledDate", "applicationDeliveredDate", "checkCashedDate", "uscisReceiptDate", "uscisNoticeDate", "rfeReceivedDate", "rfeReplyDate", "statusDate", "intermediateEadReceivedDate", "eadCardProductionOrderedDate", "eadReceivedDate", "mostRecentLUD"],
    420580: ["i824FiledDate", "i824ApprovalDate", "nvcReceiptDate", "nvcCreatedCaseDate", "nvcIssuedDS3032Date", "ds3032ArrivalDate", "ds3032SentDate", "ds3032ArrivedAtNvcDate", "nvcEntersDS3032Date", "nvcIssuesFeeReceiptDate", "feeReceiptRecvdDate", "feeSentToStLouiseDate", "checkEncachedDate", "nvcSentPkt3Date", "pkt3RecvdDate", "pkt3MailedToNvcDate", "nvcRcvdPkt3Date", "nvcReviewedPkt3Date", "caseSentToConsulateDate", "pkt4RecdDate", "entryDate", "plasticCardArrDate", "statusDate"],
    420581: ["applicationFiledDate", "applicationDeliveredDate", "checkCashedDate", "uscisReceiptDate", "uscisNoticeDate", "rfeReceivedDate", "rfeReplyDate", "statusDate"],
    420582: ["permanentResidentDate", "applicationFiledDate", "applicationDeliveredDate", "checkCashedDate", "uscisReceiptDate", "uscisNoticeDate", "vacAppointmentDate", "rfeReceivedDate", "rfeReplyDate", "statusDate", "mostRecentLUD"],
    420583: ["applicationFiledDate", "applicationDeliveredDate", "checkCachedDate", "receiptDate", "noticeDate", "fngrprintNoticeRecdDate", "rfeReceivedDate", "rfeReplyDate", "statusDate", "interviewLetterRecdDate", "interviewDate", "oathLetterRecdDate", "oathDate", "naturalizationCertRecdDate", "passportApplicationDate", "passportRecdDate"],
    420584: ["serviceOrdrFormCompletedDate", "applicationMailedDate", "applicationDeliveredDate", "appAssignProcessingDate", "docReceivedPaymentProcessedDate", "acknowledgeReceiptDate", "applicationOnHoldDate", "docPreparedDate", "docDispatchedToConsulateDate", "docRecdByConsulateDate", "pendingGovtIndApprovalDate", "photoSignAtMissionDate", "regstritationStatusGrantedDate", "docsPrintedDate", "docsSentFromIndiaDate", "docsRecdByLocalConsulateDate", "packageAcknowledgedDate", "indPassportReqByConsulateDate", "indPassportMailedToConsulateDate", "passportPreparedConsulateDate", "documentsDispatchedOutsourcingConsulateDate", "passportMailedOutDate", "ociCardRecdDate"],
    420585: ["applicationMailedDate", "applicationDeliveredDate", "paymentEncashedDate", "acknowledgementReceiptDate", "statusDate", "pioCardReceivedDate"],
    420602: ["applicationDate", "paymentEncashedDate", "acknowledgementReceiptDate", "statusDate", "visaReceivedDate"],
    713339: ["applicationDate", "acknowledgementReceiptDate", "renunciationCertificateReceivedDate", "statusDate"],
    713453: ["statusDate", "applicationFiledDate", "uscisReceiptDate", "uscisNoticeDate", "rfeReceivedDate", "rfeReplyDate", "mostRecentLUD"],
    713454: ["uscisReceiptDate", "layoffDate"],
    713455: ["hireDateBySponsoringEmployer", "greenCardApprovalDate", "usCitizenshipApplicationDate"]
};

export const colorCode = {
    status: {
        "Pending": "fffddf",
        "Approved": "f2ffef",
        "Withdrawn": "ffefef",
        "Denied": "f4f4f4",
        "Other": "fff0da",
    }
}

/**
 * Add Tracker Required If conditional validation
 */
export const requiredIfTrackers = {
    //Visitor Visa Stamping Tracker
    420561: {
        statusDate: {
            field: 'status',
            values: ['Granted', 'Approved', 'Denied', 'Withdrawn'],
            case: 'OR'
        },
        sponsorRelationship: {
            field: 'sponsorType',
            values: ['Self'],
            case: 'NOT'
        }
    },
    //H1-H4 Visa Stamping Tracker
    420562: {
        statusDate: {
            field: 'status',
            values: ['Granted', 'Approved', 'Denied', 'Withdrawn'],
            case: 'OR'
        }
    },
    //L1-L2 Visa Stamping Tracker
    420563: {
        statusDate: {
            field: 'status',
            values: ['Granted', 'Approved', 'Denied', 'Withdrawn'],
            case: 'OR'
        }
    },
    //Student Visa Stamping Tracker
    420564: {
        statusDate: {
            field: 'status',
            values: ['Granted', 'Approved', 'Denied', 'Withdrawn'],
            case: 'OR'
        }
    },
    //Fiancée Visa Stamping Tracker
    420565: {
        statusDate: {
            field: 'status',
            values: ['Granted', 'Approved', 'Denied', 'Withdrawn'],
            case: 'OR'
        }
    },
    //221(G) Tracker
    420566: {
        statusDate: {
            field: 'status',
            values: ['Granted', 'Approved', 'Denied', 'Withdrawn', 'Reaffirmed'],
            case: 'OR'
        }
    },
    //H1-H4 Visa Tracker
    420567: {
        statusDate: {
            field: 'status',
            values: ['Granted', 'Approved', 'Denied', 'Withdrawn'],
            case: 'OR'
        },
        rfeReceivedDate: {
            field: 'rfeReceived',
            values: ['Yes'],
            case: 'OR'
        }
    },
    //L1-L2 Visa Tracker
    420568: {
        statusDate: {
            field: 'status',
            values: ['Granted', 'Approved', 'Denied', 'Withdrawn'],
            case: 'OR'
        },
        rfeReceivedDate: {
            field: 'rfeReceived',
            values: ['Yes'],
            case: 'OR'
        }
    },
    //Visitors Visa Extension Tracker
    420569: {
        statusDate: {
            field: 'status',
            values: ['Granted', 'Approved', 'Denied', 'Withdrawn'],
            case: 'OR'
        },
        rfeReceivedDate: {
            field: 'rfeReceived',
            values: ['Yes'],
            case: 'OR'
        }
    },
    //Other Visa Extension Tracker
    420570: {
        statusDate: {
            field: 'status',
            values: ['Granted', 'Approved', 'Denied', 'Withdrawn'],
            case: 'OR'
        },
        rfeReceivedDate: {
            field: 'rfeReceived',
            values: ['Yes'],
            case: 'OR'
        }
    },
    //PERM Tracker
    420571: {
        statusDate: {
            field: 'status',
            values: ['Granted', 'Approved', 'Denied', 'Withdrawn', 'Certified'],
            case: 'OR'
        }
    },
    //I-129F Tracker
    420572: {
        applicationStatusDate: {
            field: 'applicationStatus',
            values: ['Granted', 'Approved', 'Denied', 'Withdrawn'],
            case: 'OR'
        }
    },
    //I-130 Tracker
    420573: {
        statusDate: {
            field: 'status',
            values: ['Granted', 'Approved', 'Denied', 'Withdrawn'],
            case: 'OR'
        },
        rfeReceivedDate: {
            field: 'rfeReceived',
            values: ['Yes'],
            case: 'OR'
        }
    },
    //Advance Parole Tracker
    420574: {
        statusDate: {
            field: 'status',
            values: ['Granted', 'Approved', 'Denied', 'Withdrawn'],
            case: 'OR'
        },
        rfeReceivedDate: {
            field: 'rfeReceived',
            values: ['Yes'],
            case: 'OR'
        }
    },
    //I-140 Tracker
    420575: {
        statusDate: {
            field: 'status',
            values: ['Granted', 'Approved', 'Denied', 'Withdrawn'],
            case: 'OR'
        },
        rfeReceivedDate: {
            field: 'rfeReceived',
            values: ['Yes'],
            case: 'OR'
        }
    },
    //I-485 Tracker
    420576: {
        i485StatusDate: {
            field: 'i485Status',
            values: ['Granted', 'Approved', 'Denied', 'Withdrawn'],
            case: 'OR'
        },
        rfeReceivedDate: {
            field: 'rfeReceived',
            values: ['Yes'],
            case: 'OR'
        },
        eadApplicationDate: {
            field: 'eadApplied',
            values: ['Yes'],
            case: 'OR'
        },
        apApplicationDate: {
            field: 'apApplied',
            values: ['Yes'],
            case: 'OR'
        }
    },
    //I-485 Interview Tracker
    420577: {

    },
    //I-751 Tracker
    420578: {
        rfeReceivedDate: {
            field: 'rfeReceived',
            values: ['Yes'],
            case: 'OR'
        }
    },
    //EAD Tracker
    420579: {
        rfeReceivedDate: {
            field: 'rfeReceived',
            values: ['Yes'],
            case: 'OR'
        }
    },
    //Immigrant Visa Tracker
    420580: {

    },
    //Replace Green Card Tracker
    420581: {
        rfeReceivedDate: {
            field: 'rfeReceived',
            values: ['Yes'],
            case: 'OR'
        },
        rfeReplyDate: {
            field: 'status',
            values: ['Granted', 'Approved', 'Denied', 'Withdrawn'],
            case: 'OR'
        }
    },
    //Reentry Permit Tracker
    420582: {
        statusDate: {
            field: 'status',
            values: ['Granted', 'Approved', 'Denied', 'Withdrawn'],
            case: 'OR'
        },
        rfeReceivedDate: {
            field: 'rfeReceived',
            values: ['Yes'],
            case: 'OR'
        }
    },
    //US Citizenship Tracker
    420583: {
        statusDate: {
            field: 'status',
            values: ['Granted', 'Approved', 'Denied', 'Withdrawn'],
            case: 'OR'
        },
        rfeReceivedDate: {
            field: 'rfeReceived',
            values: ['Yes'],
            case: 'OR'
        }
    },
    //OCI Tracker
    420584: {

    },
    //Visa Tracker
    420602: {
        statusDate: {
            field: 'status',
            values: ['Granted', 'Approved', 'Denied', 'Withdrawn'],
            case: 'OR'
        }
    },
    //Renunciation Tracker
    713339: {

    },
    //H1 Amendment Tracker
    713453: {
        statusDate: {
            field: 'status',
            values: ['Granted', 'Approved', 'Denied', 'Withdrawn'],
            case: 'OR'
        },
        rfeReceivedDate: {
            field: 'rfeReceived',
            values: ['Yes'],
            case: 'OR'
        }

    },
    //US Layoff Tracker
    713454: {

    },
    //Job Change After Greencard Tracker
    713455: {

    }
}

/**
 * Add Tracler Select Field Show text box if Other option is chosen
 */
export const otherOptions = ['visaCenter', 'visaStampDuration', 'slipColor'];

//19, 14, 41

/**
 * Quick Filter Order
 */
export const quickFltrOrd = {
    420561: [13,4,10,11,20],    // 420561	Visitor Visa Stamping Tracker
    420562: [13,4,31,32,20],    // 420562	H1-H4 Visa Stamping Tracker
    420563: [13,4,31,32,20],    // 420563	L1-L2 Visa Stamping Tracker
    420564: [13,4,31,36,20],    // 420564	Student Visa Stamping Tracker
    420565: [13,4,31,20, 22],    // 420565	Fiancée Visa Stamping Tracker
    420566: [13,4,36,20,41],    // 420566	221(G) Tracker
    420567: [13,4,31,227,228,20],   // 420567	H1-H4 Visa Tracker
    420568: [13,4,31,227,228,20],    // 420568	L1-L2 Visa Tracker
    420569: [13,4,61,227,228,20], // 420569	Visitors Visa Extension Tracker
    420570: [13,4,64,227,228,20], // 420570	Other Visa Extension Tracker
    420571: [13,4,64,227,228,20], // 420571	PERM Tracker
    420572: [13,4,71,227,228,72], // 420572	I-129F Tracker
    420573: [13,4,64,227,228,72], // 420573	I-130 Tracker
    420574: [13,4,64,227,228,20], // 420574	Advance Parole Tracker
    420575: [13,4,64,227,228,20], // 420575	I-140 Tracker
    420576: [99,100,64,230,229,20], // 420576	I-485 Tracker
    420577: [75,66,114,20], // 420577	I-485 Interview Tracker
    420578: [13,4,47,227,228,20], // 420578	I-751 Tracker
    420579: [13,4,32,227,228,20], // 420579	EAD Tracker
    420580: [13,4,64,227,228,20], // 420580	Immigrant Visa Tracker
    420581: [13,4,47,227,228,20], // 420581	Replace Green Card Tracker
    420582: [13,4,47,227,228,20], // 420582	Reentry Permit Tracker
    420583: [13,4,168,227,228,20], // 420583	US Citizenship Tracker
    420584: [20,31,197,215,227,228], // 420584	OCI Tracker
    // 420585: [], // 420585	PIO Card Tracker
    420602: [13,4,36,227,228,20], // 420602	Visa Tracker
    713339: [13,4,31,227,228,20], // 713339	Renunciation Tracker
    713453: [13,4,31,227,228,20], // 713453	H1 Amendment Tracker
    713454: [180,231,188,187], // 713454	US Layoff Tracker
    713455: [189,190,194,232]  // 713455	Job Change After Greencard Tracker
};

//229	greencardProcesingTime

// Sponsors
export const sponsorsQuickFilter = {
    caseStatus: {
        show: false,
        fieldName: 'caseStatus',
        fieldValues: [1,2,3,4,5].map((item) => {
            const varName = 'val'+item;
            return {
                value: window.immiLang?.quickFilter?.caseStatus[varName] || ''
            }
        })
    },
    sortBy: {
        show: false,
        fieldName: 'sortBy',
        fieldValues: [1,2,3,4,5,6].map((item) => {
            const varName = 'val'+item;
            const keyName = 'key'+item;
            return {
                value: window.immiLang?.quickFilter?.sortBy[varName] || '',
                key: window.immiLang?.quickFilter?.sortBy[keyName] || '',
            }
        })
    },
    employerName: {
        show: false,
        fieldName: 'employerName',
        fieldLabel: window.immiLang?.quickFilter?.employer.label || ''
    },
    cityState: {
        show: false,
        fieldName: 'cityState',
        stateList: window.immiObj?.statesList || []
    },
    jobTitle: {
        show: false,
        fieldName: 'jobTitle',
        fieldLabel: window.immiLang?.quickFilter?.jobTitle.label || ''
    },
    wageRate: {
        show: false,
        fieldName: 'wageRate',
        fieldValue: {
            min: 20,
            max: 800,
            prepend: 'K'
        }
    },
}