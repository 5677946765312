//colors to match global settings variables
const globalColors = {
        imBlue: '#0070BB',
        imRed: '#FF0000',
        imPurple: '#1B1464',
        //GRAYS AND BLACKS (lightest to darkest)
        ltWhite: '#FFFFFF',
        ltSmoke: '#F4F4F4',
        ltWhisper: '#EAEAEA',
        ltGainsboro:' #DADADA',
        ltSilver: '#BABABA',
        drkCaparol: '#888888',
        drkGrimmy: '#777777',
        drkKrylon:'#666666',
        drkNight: '#333333',
        drkBlack: '#000000',
        //LANGUAGE
        langChinese: '#DE2910',
        langSpanish: '#CF9F00',
        //SOCIAL
        ssFacebook: '#1877f2',
        ssX: '#000000',
        ssLinkedin: '#0a69bf',
        ssWhatsapp: '#20E57E',
        ssEmail: '#0070D4',
    }

const breakpoints = {
    xtraSmall: 375,
    small: 544,
    medium: 768,
    large: 992,
    xtraLarge: 1260
}

export { globalColors, breakpoints };
