<template>
    <button class="o-Btn-br" :class="!isDisabled && !noanimate ? 'o-Btn-effect-shade button--inverted '+ className : className" @click="callback($event)" :disabled="isDisabled || pauseSubmit">
        <slot>Button</slot>
        <svg viewBox="0 0 12 12" class="o-Icon__svg" :class="cssIconSize">
            <use v-bind="{'xlink:href':'/assets/build/svg/defs/svg/sprite.defs.svg#' + iconName}"
                class="o-Icon__svg__inner"></use>
        </svg>
    </button>
</template>

<script>
export default {
    name: 'buttonSvg',
    props: {
        iconName: {
            type: String,
            required: true
        },
        isDisabled: {
            type: Boolean,
            required: false
        },
        className: {
            type: Array,
            required: false
        },
        iconSize: {
            type: Number,
            required: false
        },
        noanimate: {
            type: Boolean,
            required: false
        }
    },
    data () {
        return {
            pauseSubmit: window.immiObj.pauseAction
        }
    },
    computed: {
        cssIconSize () {
            return this.iconSize ? `o-Icon__svg--${this.iconSize}` : 'o-Icon__svg--12';
        }
    },
    methods: {
        callback: function(e) {
            this.$emit('click', e);
        }
    }
}
</script>