import {
    AllAds,
    AllAdsZH,
    AllAdsES
} from './list-of-inline-ads';

class InlineAds {
    /**
     * 
     * @param {string} section Get what section eg: Visitors Visa, Schengen Visa
     * @param {string} type Get what type of ads eg: Quote or Article
     */
    
    constructor (section, type) {
        this.section = section;
        this.type = type;
        this.randomInts = [];
        this.sectionValue = null;
    }

    get advertisement () {
        return this.frameAnAdvertisement()
    }

    frameAnAdvertisement () {
        this.sectionValue =  window.immiLang && window.immiLang.currentLangCode == 'zh' ? AllAdsZH[this.section] : window.immiLang && window.immiLang.currentLangCode == 'es' ? AllAdsES[this.section] : AllAds[this.section];
        let ads = {};
        let linkText = this.type === 'quote' ? window.immiLang.getQuote : window.immiLang.readArticle;
        if(this.type == 'quote') {
            if (this.sectionValue.hasOwnProperty('general')) {
                ads = this.getRandomInt();
                linkText = ads.linkText;
            } else {
                ads = {...this.sectionValue.quote};
            }
        } else {
            if (this.sectionValue.hasOwnProperty('general')) {
                ads = this.getRandomInt();
                linkText = ads.linkText;
            } else {
                ads = this.getRandomInt();
            }
        }

        return `<a href="${ads.link}" class="o-Adv-block__img"><img src="${ads.image}"></a>
            <div class="o-Adv-block__text">
                <h2 class="o-Adv-block__title">${ads.title}</h2>
                <a href="${ads.link}" class="o-Adv-block__link">${linkText}</a>
            </div>`;
    }

    getRandomInt () {
        let sessionSection = sessionStorage.getItem('inline-cntr');
        const type = this.sectionValue.hasOwnProperty('general') ? 'general' : 'articles';
        let sectionArray = JSON.parse(JSON.stringify(this.sectionValue))[type];
        let uniqueSectionVal = [...sectionArray];
        if (sessionSection) {
            sessionSection = JSON.parse(sessionSection);
            if (sessionSection.hasOwnProperty(this.section) && sessionSection[this.section].length) {
                let existingAds = sessionSection[this.section].length ? sessionSection[this.section].map(item=>item) : [];
                if (existingAds.length === sectionArray.length) {
                    sessionSection[this.section] = [];
                    existingAds = [];
                    sessionStorage.setItem('inline-cntr', JSON.stringify(sessionSection));
                }
                uniqueSectionVal = sectionArray.filter(item=>existingAds.indexOf(item.title) == -1);
            } else {
                sessionSection[this.section] = [];
            }
        } else {
            sessionSection = {};
            sessionSection[this.section] = [];
        }
        const min = 0;
        const max = uniqueSectionVal.length - 1;

        let randomInt = Math.floor(Math.random() * (max - min + 1)) + min;
        const randomValue = uniqueSectionVal[randomInt];
        sessionSection[this.section].push(uniqueSectionVal[randomInt].title);
        sessionStorage.setItem('inline-cntr', JSON.stringify(sessionSection));
        return randomValue;
    }
}

export default InlineAds;

class CategoryAdvMapping {
    constructor (id) {
        this.id = id;
    }

    get targetedAds () {
        return generateAds ();
    }

    generateAds () {
        
    }
}